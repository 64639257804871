import * as firebase from 'firebase'

const firebaseConfig = {
    apiKey: "AIzaSyDP5JQXuQSZJd60z3G1Xh0hHKoaVrlwT5Q",
    authDomain: "reactblog-3c1a5.firebaseapp.com",
    databaseURL: "https://reactblog-3c1a5.firebaseio.com",
    projectId: "reactblog-3c1a5",
    storageBucket: "reactblog-3c1a5.appspot.com",
    messagingSenderId: "759820738111",
    appId: "1:759820738111:web:54d1268764e2433614628a",
    measurementId: "G-MQ1874V3H3"
  };

  firebase.initializeApp(firebaseConfig);

  export const database = firebase.database();