import React, { Component } from "react";
import "./auth.css";
import {
  Form,
  Input,
  Button,
  Row,
  Col,
  Card,
  PageHeader,
  Typography,
  Space
} from "antd";
import { authService } from "../services";
const { Text } = Typography
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 8,
  },
}
export class ForgotPasswordComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailSent: false
    }
  }

  onSubmit = (values) => {
    if (!values.email) return;
    authService.forgotPassword(values.email)
      .then(result => {
        if (result.isSuccess) {
          this.setState({ emailSent: true });
        }
        else {
          this.props.toastManager.add(result.message, { appearance: 'error', autoDismiss: true });
        }
      });
  };

  onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };


  render() {
    return (
      <React.Fragment>
        <Row><br /></Row>
        {this.state.emailSent &&
          <Row>
            <h3>Please Check your email for a reset password email from FabNet</h3>
          </Row>
        }
        {!this.state.emailSent &&
          <Row
            type="flex"
            justify="center"
            align="middle"
          >
            <Col span="12" >
              <Card>
                <Space direction='vertical'>

                  <PageHeader
                    className="site-page-header-responsive"
                    title="Forgot Password"
                  />
                  <Text type="secondary">
                    If you have Forgotten your password, type in your E-mail Address and we will send you a link to Reset Your Password.
              </Text>
                  <br />
                  <Form
                    id="forgot-password"
                    align="middle"
                    name="basic"
                    initialValues={{
                      remember: true,
                    }}
                    onFinish={this.onSubmit}
                    onFinishFailed={this.onFinishFailed}
                  >

                    <Form.Item
                      {...layout}
                      label="Email"
                      name="email"
                      rules={[
                        {
                          type: 'email',
                          required: true,
                          message: "Please input valid e-mail address!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item >
                      <Button type="submit" htmlType="submit">
                        Request Password Reset
                    </Button>
                    </Form.Item>
                  </Form>
                </Space>
              </Card>

            </Col>
          </Row>
        }
      </React.Fragment>
    );
  }
}