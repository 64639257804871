import React from "react";

import { Row, Col, Divider, Typography, Descriptions, Table } from "antd";

import InvoiceNotes from "./invoiceNotes";

import styles from "./mystyle.module.css";

const { Title, Text } = Typography;

const columns = [
  {
    title: "Item",
    dataIndex: "item",
    key: "item",
  },
  {
    title: "Description",
    dataIndex: "description",
    key: "description",
  },
  {
    title: "Qty",
    dataIndex: "qty",
    key: "qty",
  },
  {
    title: "Price",
    dataIndex: "price",
    align: "right",
    key: "price",
  },
  {
    title: "Total",
    dataIndex: "total",
    align: "right",
    key: "total",
  },
];

const InvoiceRender = ({
  invoiceId,
  customerName,
  billingAddress,
  invoiceDate,
  invoiceDetails,
  taxRate,
  notes,
  subTotal,
  taxTotal,
  total,
  currencySym,
}) => {
  return (
    <React.Fragment>
    <p className={styles.headingText}>Invoice</p>
    <Row>
      <Col span="9" offset="0">
        <h1>ABCD Company</h1>
        <p>
          No.107, Main Street
          <br />
          Colombo, Sri Lanka
          <br />
          11300
          <br />
          Phone: +94 76852861
          <br />
          E-mail: contact@abcd.com
          <br />
        </p>
      </Col>

      <Col span="9" offset="4">
        <p>
          Billed to:
          <br />
          {customerName}
          <br />
          {billingAddress.split("\n").map(function (item, key) {
            return (
              <span key={key}>
                {item}
                <br />
              </span>
            );
          })}
          <br />
        </p>
      </Col>
    </Row>

    <p>
      Invoice No   : {invoiceId}
      <br />
      Invoice Date: {invoiceDate}
      <br />
    </p>

    <br />
    <Table columns={columns} dataSource={invoiceDetails} pagination={false} />
    <br />
    <Row>
      <Col span="8" offset="16">
        <Descriptions bordered className={styles.totalText}>
          <Descriptions.Item label={`Sub Total (${currencySym})`} span={6}>
            <Text> {subTotal}</Text>
          </Descriptions.Item>
          <Descriptions.Item label={`${parseFloat(taxRate) * 0.01}% Tax (${currencySym})`} span={6}>
            <Text>{taxTotal}</Text>
          </Descriptions.Item>
          <Descriptions.Item label={`Total (${currencySym})`} span={6}>
            <Text>{total}</Text>
          </Descriptions.Item>
        </Descriptions>
      </Col>
    </Row>

    <br />
    <br />
          <InvoiceNotes value={notes}/>
  </React.Fragment>
  );
};

export default InvoiceRender;
