import { savePDF } from '@progress/kendo-react-pdf';

class DocService {
  createPdf = (html) => {
    savePDF(html, { 
      paperSize: 'A4',
      fileName: 'invoice.pdf',
      scale: 0.7,
      margin: 0,
    })
  }
}

const Doc = new DocService();
export default Doc;