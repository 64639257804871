import React, { Component } from "react";
import { database } from "../firebase";
import Popup from "reactjs-popup";

import {
  Row,
  Col,
  Button,
  Breadcrumb,
  Divider,
  Typography,
  Form,
  Input,
  Space,
  DatePicker,
  InputNumber,
  Select,
} from "antd";

import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";

import InvoiceRender from "./invoiceRender";

const { Title } = Typography;

const { TextArea } = Input;

const { Option } = Select;

class InvoiceCreate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      previewButtonDisabled: true,
      submitButtonDisabled: true,
      invoiceId: "",
      customerName: "",
      billingAddress: "",
      invoiceDate: "",
      invoiceDetails: [],
      invoiceDetailsCalc: [],
      taxRate: "0",
      notes: "",
      subTotal: 0,
      taxTotal: 0,
      total: 0,
      currencySym: "$",
    };

    this.onHandleSubmit = this.onHandleSubmit.bind(this);
  }

  componentDidMount() {}

  onInvoiceHomeClick = () => {
    const { history } = this.props;
    if (history) history.push("/invoice");
  };

  //this funtion submits data to firebase
  onHandleSubmit() {
    const invoice = {
      _id: "INV".concat(Date.now()),
      invoiceId: this.state.invoiceId,
      customerName: this.state.customerName,
      billingAddress: this.state.billingAddress,
      invoiceDate: this.state.invoiceDate,
      invoiceDetails: this.state.invoiceDetailsCalc,
      taxRate: this.state.taxRate,
      notes: this.state.notes,
      subTotal: this.state.subTotal,
      taxTotal: this.state.taxTotal,
      total: this.state.total,
      currencySym: this.state.currencySym,
    };
    database.ref(`/invoices/${invoice._id}`).set(invoice);
    this.setState({
      _id: "",
    });
    this.redirectToHome();
  }

  redirectToHome = () => {
    const { history } = this.props;
    if (history) history.push("/invoice");
  };

  render() {
    const onCurrencyChange = (value) => {
      this.setState({ currencySym: value });
    };

    const onUpdate = (values) => {
      if (values.notes == null) {
        this.setState({ notes: "No_Notes" });
      } else {
        this.setState({ notes: values.notes });
      }

      this.setState({
        invoiceId: values.invoiceId,
        customerName: values.customerName,
        billingAddress: values.billingAddress,
        invoiceDate: values.invoiceDate.toISOString().substring(0, 10),
        invoiceDetails: values.invoiceDetails,
        taxRate: values.taxRate,
      });

      if (values.currency == "OTH") {
        this.setState({ currencySym: values.customizeCurrency });
      }

      // these var and map funtion is to calculate total price per item
      let invoiceItemList = this.state.invoiceDetails;
      let invoiceDetailsCalc = [];
      let itemPriceList = [];
      let subTotal = 0;
      let taxTotal = 0;
      let total = 0;

      invoiceItemList.map((item) => {
        invoiceDetailsCalc.push({
          key: item.item,
          item: item.item,
          description: item.description,
          price: item.price.toFixed(2),
          qty: item.qty,
          total: (item.price * item.qty).toFixed(2),
        });
        itemPriceList.push(item.price * item.qty);
      });

      // calculate total price for all items
      itemPriceList.map((item) => {
        subTotal = subTotal + parseFloat(item);
      });

      // Calculate taxes and total
      taxTotal = subTotal * parseFloat(this.state.taxRate) * 0.01;
      total = subTotal + taxTotal;

      this.setState({
        invoiceDetailsCalc: invoiceDetailsCalc,
        subTotal: subTotal.toFixed(2),
        taxTotal: taxTotal.toFixed(2),
        total: total.toFixed(2),
        submitButtonDisabled: false,
        previewButtonDisabled: false,
      });
    };

    return (
      <React.Fragment>
        <Row>
          <Col span="18" offset="3">
            <Breadcrumb style={{ marginBottom: 10 }}>
              <Breadcrumb.Item>Home</Breadcrumb.Item>
              <Breadcrumb.Item>Supplier-Name</Breadcrumb.Item>
              <Breadcrumb.Item>Invoice</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Row>
          <Col span="3" offset="3">
            <br />
            <Button type="text" onClick={this.onInvoiceHomeClick}>
              Invoice Home         
            </Button>
          </Col>
          <Col span="12" offset="0">
            <Form
              name="dynamic_form_nest_item"
              onFinish={onUpdate}
              onSubmit={this.onHandleSubmit}
              autoComplete="off"
              hidden={this.state.showform}
            >
              <Title level={3}>Add Details for the Invoice</Title>

              <Form.Item
                name="invoiceId"
                label="Invoice ID"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="customerName"
                label="customer Name"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                name="billingAddress"
                label="customer Address"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <TextArea rows={4} />
              </Form.Item>

              <Form.Item
                name="invoiceDate"
                label="Invoice Date"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <DatePicker />
              </Form.Item>

              <Form.Item
                name="currency"
                label="Currency"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <Select
                  placeholder="Select a currency type"
                  onChange={onCurrencyChange}
                >
                  <Option value="$">USD</Option>
                  <Option value="€">Euro</Option>
                  <Option value="LKR">Sri Lankan Rupees</Option>
                  <Option value="OTH">other</Option>
                </Select>
              </Form.Item>
              <Form.Item
                noStyle
                shouldUpdate={(prevValues, currentValues) =>
                  prevValues.currency !== currentValues.currency
                }
              >
                {({ getFieldValue }) =>
                  getFieldValue("currency") === "OTH" ? (
                    <Form.Item
                      name="customizeCurrency"
                      label="Other Currency Symbol"
                      rules={[
                        {
                          required: true,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  ) : null
                }
              </Form.Item>

              <Form.List name="invoiceDetails">
                {(fields, { add, remove }) => {
                  return (
                    <div>
                      {fields.map((field) => (
                        <Space
                          key={field.key}
                          style={{ display: "flex", marginBottom: 8 }}
                          align="start"
                        >
                          <Form.Item
                            {...field}
                            name={[field.name, "key"]}
                            fieldKey={[field.fieldKey, "key"]}
                            values={field.key}
                          ></Form.Item>

                          <Form.Item
                            {...field}
                            name={[field.name, "item"]}
                            label="item"
                            fieldKey={[field.fieldKey, "item"]}
                            rules={[
                              {
                                required: true,
                                message: "Missing item name",
                              },
                            ]}
                          >
                            <Input placeholder="item Name" />
                          </Form.Item>

                          <Form.Item
                            {...field}
                            name={[field.name, "description"]}
                            label="description"
                            fieldKey={[field.fieldKey, "description"]}
                            rules={[
                              {
                                required: true,
                                message: "Missing description",
                              },
                            ]}
                          >
                            <Input placeholder="description" />
                          </Form.Item>

                          <Form.Item
                            {...field}
                            name={[field.name, "qty"]}
                            label="Qty"
                            fieldKey={[field.fieldKey, "qty"]}
                            rules={[{ required: true, message: "Missing Qty" }]}
                          >
                            <InputNumber placeholder="Qty" min={0}/>
                          </Form.Item>

                          <Form.Item
                            {...field}
                            name={[field.name, "price"]}
                            label={`Unit price(${this.state.currencySym})`}
                            fieldKey={[field.fieldKey, "price"]}
                            rules={[
                              { required: true, message: "Missing Price" },
                            ]}
                          >
                            <InputNumber placeholder="Price" min={0}/>
                          </Form.Item>

                          <MinusCircleOutlined
                            onClick={() => {
                              remove(field.name);
                            }}
                          />
                        </Space>
                      ))}

                      <Form.Item>
                        <Button
                          type="dashed"
                          onClick={() => {
                            add();
                          }}
                          block
                        >
                          <PlusOutlined /> Add Invoice Item
                        </Button>
                      </Form.Item>
                    </div>
                  );
                }}
              </Form.List>

              <Form.Item
                name="taxRate"
                label="Tax rate (%)"
                rules={[
                  {
                    required: true,
                  },
                ]}
              >
                <InputNumber
                  defaultValue={100}
                  min={0}
                  max={100}
                  formatter={(value) => `${value}%`}
                  parser={(value) => value.replace("%", "")}
                  placeholder="Tax Rate(%)"
                />
              </Form.Item>

              <Form.Item name="notes" label="Notes">
                <TextArea
                  rows={4}
                  placeholder="Use this area to include details such as invoice validity period, terms/conditions etc."
                />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Update
                </Button>
                  
                <Popup
                  modal
                  trigger={
                    <Button
                      type="primary"
                      disabled={this.state.previewButtonDisabled}
                    >
                      Preview
                    </Button>
                  }
                >
                  {() => (
                    <InvoiceRender
                      invoiceId={this.state.invoiceId}
                      customerName={this.state.customerName}
                      billingAddress={this.state.billingAddress}
                      invoiceDate={this.state.invoiceDate}
                      invoiceDetails={this.state.invoiceDetailsCalc}
                      taxRate={this.state.taxRate}
                      notes={this.state.notes}
                      subTotal={this.state.subTotal}
                      taxTotal={this.state.taxTotal}
                      total={this.state.total}
                      currencySym={this.state.currencySym}
                    />
                  )}
                </Popup>
                  
                <Button
                  type="danger"
                  onClick={this.onHandleSubmit}
                  disabled={this.state.submitButtonDisabled}
                >
                  submit
                </Button>
              </Form.Item>
            </Form>

            <Divider />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default InvoiceCreate;
