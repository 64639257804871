import axios from '../config/API';
import { trackPromise } from 'react-promise-tracker';
// import { FormData } from 'form-data';

export const rfqService = {
    SendRFQ,
    GetRFQ,
    fetchReceivedRFQs,
    fetchSentRFQs,
    fetchSentRFQsByUser,
    changeStatus,
    SendRespond
};

async function SendRFQ(RFQ) {
    //console.log(RFQ)
    const formData = new FormData();

    RFQ.files.forEach((file) => {
        //console.log(file)
        formData.append("files", file);
    });

    formData.append("message", RFQ.message)
    formData.append("title", RFQ.title)
    formData.append("responceNeededBy", RFQ.responceNeededBy)
    formData.append("supplierId", RFQ.supplierId)


    try {
        let responce = await trackPromise(axios.post('/RFQ/create', formData))
        return (responce.data);
    }
    catch (err) {
        //console.error(err);
        return { isSuccess: false, message: "Unable to Send RFQ" };
    }
}

async function GetRFQ(id) {
    try {
        const result = await trackPromise(axios.get(`/RFQ/${id}`));
        //console.log(result.data)
        return result.data;
    }
    catch (err) {
        //console.error(err);
        return { isSuccess: false, message: "Unable to get RFQ" };
    }
}
async function fetchSentRFQs(companyId) {
    try {
        const result = await trackPromise(axios.get(`/RFQ/view-all/sent`));
        //console.log(result);
        return result.data;
    }
    catch (err) {
        //console.error(err);
        return { isSuccess: false, message: "Unable to get Sent RFQs" };
    }
}
async function fetchSentRFQsByUser(userId) {
    try {
        const result = await trackPromise(axios.get(`/RFQ/view-all/sentbyuser?userId=${userId}`));
        //console.log(result);
        return result.data;
    }
    catch (err) {
        //console.error(err);
        return { isSuccess: false, message: "Unable to get Sent RFQs" };
    }
}
async function fetchReceivedRFQs(companyId) {
    try {
        const result = await trackPromise(axios.get(`/RFQ/view-all/received`));
        //console.log(result);
        return result.data;
    }
    catch (err) {
        //console.error(err);
        return { isSuccess: false, message: "Unable to get received RFQs" };
    }
}

async function changeStatus(id, status) {
    try {
        const result = await trackPromise(axios.put(`/RFQ/${id}/changeStatus/${status}`));
        //console.log(result.data)
        // return result.data;
        return { isSuccess: true, message: result.data.message };
    }
    catch (err) {
        //console.error(err);
        return { isSuccess: false, message: "Unable to change RFQ status." };
    }
}

async function SendRespond(id, message, fileList) {

    const formData = new FormData();

    fileList.forEach((file) => {
        //console.log(file)
        formData.append("files", file);
    });
    formData.append("message", message)

    //console.log(formData);

    try {
        let responce = await trackPromise(axios.post(
            `/RFQ/${id}/sendRespond`,
            formData,
        ))
        return (responce.data);
    }
    catch (err) {
        //console.error(err);
        return { isSuccess: false, message: "Unable to send RFQ respond." };
    }
}