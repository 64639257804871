import React, { Component } from "react";
import { database } from "../firebase";
import _ from "lodash";
import "react-quill/dist/quill.snow.css";
import renderHTML from "react-render-html";

import { Row, Col, Card, Typography, Button, Breadcrumb } from "antd";

const { Title } = Typography;

class KnowledgeBasePostDetails extends Component {
  state = { posts: "", postId: "", post: "" };

  componentDidMount() {
    database.ref("/posts").on("value", (snapshot) => {
      this.setState({
        posts: snapshot.val(),
      });
    });

    // get post ID from the URL and set it in state.postId
    const postId = this.props.match.params.id;
    this.setState({ postId });
  }

  renderPost() {
    return _.map(this.state.posts, (post) => {
      if (post._id === this.state.postId) {
        return (
          <Card title={post.title} style={{ marginBottom: 15 }}>
            <p>{renderHTML(post.body)}</p>
          </Card>
        );
      }
    });
  }

  onMainPageClick = () => {
    const { history } = this.props;
    if (history) history.push("/");
  };

  onKnowledgeBaseClick = () => {
    const { history } = this.props;
    if (history) history.push("/knowledge-base");
  };

  onCreateArticleClick = () => {
    const { history } = this.props;
    if (history) history.push("/knowledge-base-create");
  };


  render() {
    return (
      <div className="container">
        <Row>
          <Col span="18" offset="3">
            <Breadcrumb style={{ marginBottom: 10 }}>
              <Breadcrumb.Item>Home</Breadcrumb.Item>
              <Breadcrumb.Item>Knowledge Base</Breadcrumb.Item>
              <Breadcrumb.Item>Post</Breadcrumb.Item>
              <Breadcrumb.Item>{this.state.postId}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Row>
          <Col span="3" offset="3">
          <br />
            <Title level={3}>¤ Useful Links</Title>
            <Button type="text" onClick={this.onMainPageClick}>
              Main Page
            </Button>
            <Button type="text" onClick={this.onKnowledgeBaseClick}>
              Knowledge Base
            </Button>
            <br />
            <Button type="text" onClick={this.onCreateArticleClick}>
              Create Article
            </Button>
            <br />
            <Button type="text">Current Events</Button>
            <br />
            <Button type="text">Donate</Button>
            <br />
            <Button type="text">Community portal</Button>

          </Col>
          <Col span="12" offset="0">
            <br />
            {this.renderPost()}
            <br />
          </Col>
        </Row>
      </div>
    );
  }
}

export default KnowledgeBasePostDetails;
