import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./fixedSiderLayout.css";

import { Layout, Menu, Row, Alert, Col, Button, Space, Avatar, Dropdown } from "antd";
import { authService } from "../../services";
import {
    TeamOutlined,
    UserOutlined,
    SettingOutlined,
    ShopOutlined,
    HomeOutlined,
    DashboardOutlined,
    DeploymentUnitOutlined,
    UserSwitchOutlined,
    LogoutOutlined,
    FileTextOutlined,
    FileExclamationOutlined

} from "@ant-design/icons";

// import { Navbar } from "./navbar";
// import { SideBarMenu } from "../components/SidebarMenu";

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

class LayoutClass extends Component {

    constructor(props) {
        super(props);
        this.state = {
            sideMenuCollpsed: false,
            currentUser: null,
        };
    }

    onCollapse = (sideMenuCollpsed, type) => {
        this.setState({ sideMenuCollpsed });
    };


    handleLogout = () => {
        authService.logout();
        this.props.history.push("/login");
    };

    isLoggedIn = () => {
        const currentUser = authService.currentUserValue;
        this.state.currentUser = currentUser
        return !!currentUser && !!currentUser.token;
    };

    getRoles = () => {
        let roles = authService.currentUserValue?.roles;
        return roles;
    }

    ProfileDropdownMenu = (
        <Menu/*  theme="dark" */>
            <Menu.Item>
                <Button type="link"
                    onClick={() => this.props.history.push('/profile/userData')}
                    icon={<UserSwitchOutlined />}>
                    Profile
                </Button>
            </Menu.Item>
            <Menu.Item>
                <Button type="link"
                    onClick={this.handleLogout}
                    icon={<LogoutOutlined />}>
                    Log Out
                </Button>
            </Menu.Item>
        </Menu>
    );






    render() {
        const { sideMenuCollpsed } = this.state;
        return (
            <Layout style={{ minHeight: '100vh' }}>
                {this.isLoggedIn() &&
                    <Sider
                        onCollapse={this.onCollapse}
                        collapsible
                        breakpoint="lg"
                        collapsed={sideMenuCollpsed}
                        className="sider"
                    >
                        <div id='logo-holder'
                            style={{
                                position: 'fixed',
                                left: 0
                            }}
                            className="logo"
                            onClick={() => this.props.history.push("/")}
                        >
                            {
                                !sideMenuCollpsed &&
                                <img src="/images/FabLogo1.png" alt="FabLanka Logo" height="64" />
                            }
                            {
                                sideMenuCollpsed &&
                                <img src="/images/FabLogo_compact.png" alt="FabLanka Logo" height="64" />
                            }
                        </div>
                        <Menu
                            mode="inline"
                            // theme="dark"
                            defaultSelectedKeys={["dashboard"]}
                            defaultOpenKeys={["dashboard"]}
                            style={{
                                height: "100%",
                                borderRight: 0,
                                paddingTop: '75px',
                                overflow: 'auto',
                                position: 'fixed',
                                left: 0,
                                top: 64,
                                maxWidth: '200px'
                            }}
                            className="sider-menu"
                        >
                            <Menu.Item className="sider-btn" onClick={() => this.props.history.push('/dashboard')} key="dashboard" icon={<DashboardOutlined />}>Dashboard</Menu.Item>
                            <Menu.Item className="sider-btn" onClick={() => this.props.history.push('/profile/userData')} key="userData" icon={<UserSwitchOutlined />}>Profile</Menu.Item>

                            {this.getRoles().includes("Admin")
                                &&
                                <Menu mode="inline"
                                    // theme="dark"
                                    defaultSelectedKeys={["dashboard"]}
                                    defaultOpenKeys={["dashboard"]}
                                    className="sider-menu"
                                >
                                    <Menu.Item className="sider-btn" onClick={() => this.props.history.push(`/manage/company/members`)} key="companyMembers" icon={< TeamOutlined />}>Company Members</Menu.Item>
                                    <Menu.Item className="sider-btn" onClick={() => this.props.history.push(`/manage/company/info`)} key="companyInfo" icon={< TeamOutlined />}>Company Info</Menu.Item>
                                    <Menu.Item className="sider-btn" onClick={() => this.props.history.push(`/manage/company/edit`)} key="companyEdit" icon={< TeamOutlined />}>Edit Company</Menu.Item>

                                </Menu>
                            }
                            <Menu.Item className="sider-btn" onClick={() => this.props.history.push(`/manage/rfi/list`)} key="rfiList" icon={< TeamOutlined />}>RFI List</Menu.Item>
                            <Menu.Item className="sider-btn" onClick={() => this.props.history.push(`/manage/rfq/list`)} key="rfqList" icon={< TeamOutlined />}>RFQ List</Menu.Item>

                            <Menu.Item className="sider-btn" onClick={() => this.props.history.push('/dashboard')} key="settings" icon={<SettingOutlined />}>Settings</Menu.Item>
                        </Menu>
                    </Sider>
                }

                <Layout className="site-layout">
                    {
                        <Header className="header" style={{ position: 'fixed', zIndex: 1, width: '100%' }} >
                            <Row>
                                <Col
                                    span={4}
                                    style={{ position: "fixed", right: '10px' }}
                                >
                                    {(!this.isLoggedIn() && (
                                        <Space style={{ position: "absolute", right: 10, margin: "auto" }}>
                                            <Button
                                                type="primary"
                                                onClick={() => this.props.history.push("/login")}
                                            >
                                                Log In
                                            </Button>
                                            <Button onClick={() => this.props.history.push("/register")}>
                                                Register
                                            </Button>
                                        </Space>
                                    )) || (

                                            <Dropdown
                                                overlay={this.ProfileDropdownMenu}
                                                arrow
                                                placement="bottomRight"
                                                overlayClassName="acc-dropdown"
                                            >
                                                <Button
                                                    style={{ color: '#242425' }}
                                                    className="avatar-btn"
                                                    type="link"
                                                    onClick={() => this.props.history.push(`/profile/userData`)}
                                                >
                                                    {this.state.currentUser.avatar &&
                                                        <Avatar src={this.state.currentUser.avatar} />
                                                    }
                                                    {!this.state.currentUser.avatar &&
                                                         <Avatar>{this.state.currentUser.firstName!=null?this.state.currentUser.firstName.charAt(0):""}</Avatar>
                                                    }
                                                    <span> {this.state.currentUser.firstName?this.state.currentUser.firstName:"User"}</span>
                                                </Button>
                                            </Dropdown>

                                            //   <Space style={{ position: "absolute", margin: "auto" }}>
                                            //     <Button
                                            //       // style={{ display: 'inline-grid', justifyItems: "center" }}
                                            //       className="avatar-btn"
                                            //       type="link"
                                            //       onClick={() => this.props.history.push(`/profile/userData`)}
                                            //     >
                                            //       {this.state.currentUser.avatar &&
                                            //         <Avatar src={this.state.currentUser.avatar} />
                                            //       }
                                            //       {!this.state.currentUser.avatar &&
                                            //         <Avatar>{this.state.currentUser.firstName.charAt(0)}</Avatar>
                                            //       }
                                            //       <span> {this.state.currentUser.firstName}</span>
                                            //     </Button>
                                            //     <Button type="link" onClick={this.handleLogout}>
                                            //       Log Out
                                            // </Button>
                                            //   </Space>
                                        )}
                                </Col>
                            </Row>
                        </Header>
                    }


                    <Content className="site-layout-content">
                        <Layout className="site-layout-background" >


                            {this.props.showMessage && (
                                <div className="Header-Message">
                                    <Row>
                                        <Col span="24" offset="0" align="center">
                                            <Alert
                                                message="Situational Special Messages and Links"
                                                type="info"
                                                closable
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            )}



                            <Content className="main-content-area">
                                {this.props.children}
                            </Content>

                        </Layout>
                    </Content>

                    <Footer className="footer">
                        FabLanka ©2020
                    </Footer>


                </Layout>
            </Layout>
        );
    }
}
export const FixedSiderLayout = withRouter(LayoutClass);
