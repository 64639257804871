import React, { Component } from "react";
import ReactDOM from "react-dom";
import GoogleLogin from "react-google-login";
import Axios from "axios";
import "./auth.css";
import { notification, Button } from "antd";
import { authService } from "../services";
import "./sociallogin.css";
import { GoogleOutlined } from "@ant-design/icons";

export class googleButton extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  googleResponse = async (response) => {
    const result = await authService.googleLogin(response.tokenId);
    if (!result.isSuccess) {
      this.notify(result.message, 'error');
    }
    else {
      this.props.history.push('/dashboard');
    }
  };

  googleFailedResponse = () => {
    this.notify("Unable to Login with Google", 'error');
  }

  notify = (message, type) => {
    if (type === 'success') {
      notification.success({
        message, placement: 'topRight'
      });
    }
    else {
      notification.error({
        message, placement: 'topRight'
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <div className="googleLogin">
          <GoogleLogin
            clientId="718550834768-u2p57lsi3ej01ji3vfuov0ml33ms150u.apps.googleusercontent.com"
            render={(renderProps) => (
              <button
                className="google-button"
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
              >
                <GoogleOutlined className="social-media-logo" />
                Login with Google
              </button>
            )}
            buttonText="Login"
            cssClass="google-button"
            onSuccess={this.googleResponse}
            onFailure={this.googleFailedResponse}
            cookiePolicy={"single_host_origin"}
          />
        </div>
      </React.Fragment>
    );
  }
}

export default googleButton;
