import React, { Component } from "react";
import { Table, Space, Button, Modal, Form, Input, List, Row, Col, Select, Divider } from 'antd';
import { supplierService, userService } from "../../services";
import { withToastManager } from "react-toast-notifications";
import {
    FacebookOutlined,
    TwitterOutlined,
    LinkedinOutlined,
    PlusOutlined
} from '@ant-design/icons';

const { Option } = Select;

class CompanyPageComponent extends Component {
    form = null;
    constructor(props) {
        super(props);
        this.state = {
            supplier: null,
            modals: {
                addCertificate: false,
                addProduct: false,
                addService: false
            },
            industryTypes: [],
            productCategories: []
        };
    }

    componentDidMount() {
        this.fetchData();
    }


    fetchData = async () => {
        let res = await userService.getUserData();
        if (res.isSuccess) {
            this.fetchSupplier(res.dto.company.id)
        } else {
            this.props.toastManager.add(res.message, { appearance: 'error' });
        }
    }


    fetchSupplier = (id) => {

        supplierService.fetchSupplier(id)
            .then(result => {
                if (result.isSuccess) {
                    let supplier = result.dto;
                    this.setState({ supplier });
                }
                else {
                    this.props.toastManager.add(result.message, { appearance: 'error' });
                }
            });
    }

    addNewCertificate = (values) => {
        supplierService.addCertification(this.props.match.params.id, { title: values.description })
            .then(result => {
                if (result.isSuccess) {
                    this.fetchSupplier();
                    this.form = null;
                    let modals = { ...this.state.modals };
                    modals.addCertificate = false;
                    this.setState({ modals });
                }
                else {
                    this.props.toastManager.add(result.message, { appearance: 'error' });
                }
            });
    }

    onAddService = () => {
        supplierService.getIndustryTypes()
            .then(result => {
                if (result.isSuccess) {
                    let industryTypes = result.dto;
                    let modals = { ...this.state.modals };
                    modals.addService = true;
                    this.setState({ modals, industryTypes });
                }
                else {
                    this.props.toastManager.add(result.message, { appearance: 'error' });
                }
            });
    }

    addService = (values) => {
        supplierService.addService(this.props.match.params.id, { title: values.title, category: values.industry, description: values.description })
            .then(result => {
                if (result.isSuccess) {
                    this.fetchSupplier();
                    this.form = null;
                    let modals = { ...this.state.modals };
                    modals.addService = false;
                    this.setState({ modals });
                }
                else {
                    this.props.toastManager.add(result.message, { appearance: 'error' });
                }
            });
    }

    onServiceItemClick = (item) => {
        const modal = Modal.info({
            title: item.title,
            content: <div>
                <Divider />
                <div style={{ marginBottom: 15 }}>Category: <span style={{ fontWeight: 'bold' }}>{item.category}</span></div>
                Description:
                <p>{item.description}</p>
            </div>,
            centered: true
        });

    }

    onAddProduct = () => {
        supplierService.getProductCategories()
            .then(result => {
                if (result.isSuccess) {
                    let productCategories = result.dto;
                    let modals = { ...this.state.modals };
                    modals.addProduct = true;
                    this.setState({ modals, productCategories });
                }
                else {
                    this.props.toastManager.add(result.message, { appearance: 'error' });
                }
            });
    }

    addProduct = (values) => {
        supplierService.addProduct(this.props.match.params.id, { title: values.title, category: values.industry, description: values.description })
            .then(result => {
                if (result.isSuccess) {
                    this.fetchSupplier();
                    this.form = null;
                    let modals = { ...this.state.modals };
                    modals.addProduct = false;
                    this.setState({ modals });
                }
                else {
                    this.props.toastManager.add(result.message, { appearance: 'error' });
                }
            });
    }

    onProductItemClick = (item) => {
        const modal = Modal.info({
            title: item.title,
            content: <div>
                <Divider />
                <div style={{ marginBottom: 15 }}>Category: <span style={{ fontWeight: 'bold' }}>{item.category}</span></div>
                Description:
                <p>{item.description}</p>
            </div>,
            centered: true
        });

    }

    render() {
        const { supplier } = this.state;

        if (!supplier) return <div />;

        return (
            <div style={{ marginTop: 50 }}>
                <h3 style={{ color: 'green', fontSize: '1.5em' }}>{supplier.name.toUpperCase()}</h3>
                <div style={{ float: 'right' }}>
                    <img src={supplier.logo} /><br />
                    <span>
                        {supplier.facebookHandle && <FacebookOutlined onClick={() => window.open(supplier.facebookHandle, '_blank')} style={{ marginLeft: 10, fontSize: 30, marginTop: 15, color: 'lightBlue' }} />}
                        {supplier.linkedInHandle && <LinkedinOutlined onClick={() => window.open(supplier.linkedInHandle, '_blank')} style={{ marginLeft: 10, fontSize: 30, marginTop: 15, color: 'lightBlue' }} />}
                        {supplier.twitterHandle && <TwitterOutlined onClick={() => window.open(supplier.twitterHandle, '_blank')} style={{ marginLeft: 10, fontSize: 30, marginTop: 15, color: 'lightBlue' }} />}
                    </span>
                </div>
                <p>
                    <span>{supplier.email}</span><br />
                    <span>{supplier.phone}</span><br />
                    <span>{supplier.address?.fullAddress}</span><br />
                    {supplier.website && <a href={supplier.website} target="_blank">{supplier.website}</a>}
                </p>

                <h4 style={{ marginTop: 25 }}>BUSINESS DESCRIPTION</h4>
                <p style={{ maxWidth: '60%', textAlign: 'justify' }}>{supplier.aboutUs}</p>
                <List
                    size="small"
                    header={
                        <div style={{ display: 'flex' }}>
                            <h4>CERTIFICATIONS</h4>
                            <Button size="small" type="primary" icon={<PlusOutlined />} style={{ marginLeft: 10 }} onClick={() => {
                                let modals = { ...this.state.modals };
                                modals.addCertificate = true;
                                this.setState({ modals });
                            }} />
                        </div>
                    }
                    locale={{ emptyText: 'No Certifications' }}
                    dataSource={supplier.certifications}
                    renderItem={item => <List.Item style={{ paddingBottom: 2, paddingTop: 2 }}>- {item}</List.Item>}
                />
                <List
                    size="small"
                    style={{ marginTop: 25 }}
                    header={
                        <div style={{ display: 'flex' }}>
                            <h4>SERVICES</h4>
                            <Button size="small" type="primary" icon={<PlusOutlined />} style={{ marginLeft: 10 }} onClick={this.onAddService} />
                        </div>
                    }
                    dataSource={supplier.services}
                    locale={{ emptyText: 'No Services' }}
                    renderItem={item => <List.Item style={{ paddingBottom: 2, paddingTop: 2 }} onClick={() => this.onServiceItemClick(item)}>- <span style={{ cursor: 'pointer' }}>{item.title}</span></List.Item>}
                />
                <List
                    style={{ marginTop: 25 }}
                    size="small"
                    header={
                        <div style={{ display: 'flex' }}>
                            <h4>PRODUCTS</h4>
                            <Button size="small" type="primary" icon={<PlusOutlined />} style={{ marginLeft: 10 }} onClick={this.onAddProduct} />
                        </div>
                    }
                    locale={{ emptyText: 'No Products' }}
                    dataSource={supplier.products}
                    renderItem={item => <List.Item style={{ paddingBottom: 2, paddingTop: 2 }} onClick={() => this.onProductItemClick(item)}>- <span style={{ cursor: 'pointer' }}>{item.title}</span></List.Item>}
                />

                {this.state.modals.addCertificate &&
                    <Modal
                        title="Add Certificate"
                        centered
                        visible={this.state.modals.addCertificate}
                        onOk={() => {
                            this.form
                                .validateFields()
                                .then(values => {
                                    this.form.resetFields();
                                    this.addNewCertificate(values);
                                })
                                .catch(info => {
                                    console.log('Validate Failed:', info);
                                });
                        }}
                        onCancel={() => {
                            let modals = { ...this.state.modals };
                            modals.addCertificate = false;
                            this.setState({ modals });
                            this.form = null;
                        }}
                    >
                        <Form
                            form={this.form}
                            ref={_ref => this.form = _ref}
                            layout="vertical"
                            name="form_new_certificate"
                        >
                            <Form.Item name="description" label="Description">
                                <Input />
                            </Form.Item>
                        </Form>
                    </Modal>
                }
                {this.state.modals.addService &&
                    <Modal
                        title="Add Service"
                        centered
                        visible={this.state.modals.addService}
                        onOk={() => {
                            this.form
                                .validateFields()
                                .then(values => {
                                    this.form.resetFields();
                                    this.addService(values);
                                })
                                .catch(info => {
                                    console.log('Validate Failed:', info);
                                });
                        }}
                        onCancel={() => {
                            let modals = { ...this.state.modals };
                            modals.addService = false;
                            this.setState({ modals });
                            this.form = null;
                        }}
                    >
                        <Form
                            form={this.form}
                            ref={_ref => this.form = _ref}
                            layout="vertical"
                            name="form_new_service"
                        >
                            <Form.Item name="title" label="Title">
                                <Input />
                            </Form.Item>
                            <Form.Item name="industry" label="Industry" rules={[{ required: true }]}>
                                <Select
                                    placeholder="Select Service Type"
                                    allowClear
                                >
                                    {this.state.industryTypes.map((type, idx) => <Option value={type.id} key={idx}>{type.industry}</Option>)}
                                </Select>
                            </Form.Item>
                            <Form.Item name="description" label="Description">
                                <Input.TextArea />
                            </Form.Item>
                        </Form>
                    </Modal>
                }
                {this.state.modals.addProduct &&
                    <Modal
                        title="Add Product"
                        centered
                        visible={this.state.modals.addProduct}
                        onOk={() => {
                            this.form
                                .validateFields()
                                .then(values => {
                                    this.form.resetFields();
                                    this.addProduct(values);
                                })
                                .catch(info => {
                                    console.log('Validate Failed:', info);
                                });
                        }}
                        onCancel={() => {
                            let modals = { ...this.state.modals };
                            modals.addProduct = false;
                            this.setState({ modals });
                            this.form = null;
                        }}
                    >
                        <Form
                            form={this.form}
                            ref={_ref => this.form = _ref}
                            layout="vertical"
                            name="form_new_service"
                        >
                            <Form.Item name="title" label="Title">
                                <Input />
                            </Form.Item>
                            <Form.Item name="category" label="Category" rules={[{ required: true }]}>
                                <Select
                                    placeholder="Select Product Category"
                                    allowClear
                                >
                                    {this.state.productCategories.map((category, idx) => <Option value={category.id} key={idx}>{category.category}</Option>)}
                                </Select>
                            </Form.Item>
                            <Form.Item name="description" label="Description">
                                <Input.TextArea />
                            </Form.Item>
                        </Form>
                    </Modal>
                }
            </div >
        );
    }
}

export const CompanyInfoPage = withToastManager(CompanyPageComponent);