import React from "react";
import { Typography, Space, Divider, Checkbox } from "antd";
import Item from "antd/lib/list/Item";

const { Title } = Typography;

const SearchFilter = ({
    title,
    items,
    selectedItems,
    onItemSelect,
}) => {
    if (items) {
        return (
            <Space direction="vertical">
                <strong >{title}</strong>

                <Checkbox.Group
                    style={{ width: '100%' }}
                    onChange={onItemSelect}
                    options={items}
                    value={selectedItems}
                />

            </Space>
        )
    } else {
        return (<Space />)
    }
}




export { SearchFilter };
