import React, { useState } from "react";
import {
  Row,
  Col,
  Divider,
  Button,
  Typography,
  Input,
  Select,
  Card,
} from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";

import "./knowledgeBase.css";

const { Search } = Input;
const { Option } = Select;
const { Title, Text } = Typography;

function KnowledgeBase() {
  const [searchType, setSearchType] = useState("All Categories");
  const history = useHistory();

  function onSelectSearchType(value) {
    setSearchType(value);
  }

  function handleSearchClick(value) {
    console.log(value);
    history.push("/knowledge-base-posts");
  }

  function handleCreateClick() {
    history.push("/knowledge-base-create");
  }

  const selectBefore = (
    <Select
      onChange={onSelectSearchType}
      defaultValue={searchType}
      className="select-before"
      size="large"
    >
      <Option value="All Categories">All Categories</Option>
      <Option value="Aerospace Engineering">Aerospace Engineering</Option>
      <Option value="Automotive Engineering">Automotive Engineering</Option>
      <Option value="Electrical Engineering">Electrical Engineering</Option>
      <Option value="Civil Engineering">Civil Engineering</Option>
      <Option value="Production Engineering">Production Engineering</Option>
      <Option value="CAD Models">CAD Models</Option>
      <Option value="3D Printing">3D Printing</Option>
    </Select>
  );

  return (
    <React.Fragment>
      <div className="Content-logo">
        <Row>
          <Col span="24" offset="0" align="center">
            <Title style={{ fontSize: "4em", marginBottom: 5 }}>
              FABNET - KnowledgeBase
            </Title>
            <Text level={4} style={{ fontSize: "1.5em" }}>
              For Suppliers <span className="subtitleSeparator">|</span>{" "}
              KnowledgeBase <span className="subtitleSeparator">|</span>{" "}
              Insights, Tools, and More...
            </Text>
          </Col>
        </Row>
      </div>
      <div className="Content-InfoText">
        <Row>
          <Col span="24" offset="0" align="center">
            <h1>Learn more about engineering</h1>
          </Col>
        </Row>
      </div>
      <div className="Content-SearchBar">
        <Input.Group size="large">
          <Row justify="center">
            <Col span={16}>
              {selectBefore}
              <Search
                placeholder={"Search for " + searchType}
                style={{ width: "70%" }}
                enterButton="Search"
                size="large"
                //onSearch={handleSearchClick(value) => console.log(value)}
                //onSearch={(value) => handleSearchClick(value)}
                onSearch={handleSearchClick}
              />
            </Col>
          </Row>
        </Input.Group>
      </div>
      <div className="Content-CreateArticle">
        <Row justify="center">
          <Col span="12" align="center">
            <Button type="primary" danger onClick={handleCreateClick}>
              Or create a article in Knowledge Base
            </Button>
          </Col>
        </Row>
      </div>
      <Divider />
      <div className="Content-CreateArticle">
        <Row gutter={8}>
          <Col span={8} offset="3">
            <Card title="From today's featured article" bordered={false}>
              <p>
                <strong>Condition-based maintenance (CBM)</strong> is a
                maintenance strategy that monitors the actual condition of an
                asset to decide what maintenance needs to be done. CBM dictates
                that maintenance should only be performed when certain
                indicators show signs of decreasing performance or upcoming
                failure.
              </p>
            </Card>
          </Col>
          <Col span={8} offset="0">
            <Card title="In the news" bordered={false}>
              <ul>
                <li>
                  Details of new Mercedes Benz S class are released today.
                </li>
                <li>Boeing intends to end 747 production in 2022.</li>
                <li>
                  Laser Allows Solid-State Refrigeration of a Semiconductor
                  Material
                </li>
                <li>
                  Today AMD is announcing three new processors into the Ryzen
                  3000 family.
                </li>
              </ul>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
}

export default KnowledgeBase;
