import React, { Component } from "react";
import { getSortList } from "../../services/sortListService";
import { search } from "../../services/searchServices";
import _ from "lodash";
import {
	Row,
	Col,
	Button,
	Typography,
	Breadcrumb,
	Divider,
	Pagination,
	Layout
} from "antd";
import { MasterSearchBar, searchCatagories } from "../../components/MasterSearchBar";
// import SortListGroup from "../../components/SearchResult/SortListGroup";
// import SearchFilter from "../../components/SearchResult/SearchFilter";
import { ServiceProductResultCard, SupplierResultCard, SearchFilter, SortListGroup } from "../../components/SearchResult";
const { Title, Text } = Typography;
const { Header, Footer, Sider, Content } = Layout;


export default class SearchResult extends Component {
	state = {
		results: [],
		ServiceType: [],
		CompanyType: [],
		district: [],
		certificationList: [],
		sortList: [],

		totalResultCount: 0,
		filteredResultCount: 0,
		currentPage: 1,
		pageSize: 10,
		pageCount: 1,
		searchQuery: "",

		selectedCompanyTypes: [],
		selectedDistricts: [],
		selectedCertificates: [],

		sortPath: "service.title",
		sortOrder: "asc",

		searchCat: "",
		keyword: ""
	}
	// ------------------------------------------------------------------------------------

	getval() {
		const query = new URLSearchParams(this.props.location.search);
		const keyword = query.get('keyword');
		const category = query.get('category');
		return ({ keyword, category })
	}


	componentWillMount() {
		const sortList = getSortList();

		const query = new URLSearchParams(this.props.location.search);
		const keyword = query.get('keyword');
		const searchCategory = query.get('category');
		const selectedCompanyTypes = query.get('companyTypes') == null ? [] : query.get('companyTypes').split(',');
		const selectedDistricts = query.get('districts') == null ? [] : query.get('districts').split(',');
		const selectedCertificates = query.get('certificates') == null ? [] : query.get('certificates').split(',');
		const sort = query.get('sort') == null ? "service.title+asc" : query.get('sort');
		const page = query.get('page') == null ? 1 : query.get('page');
		const pageSize = query.get('pageSize') == null ? 10 : query.get('pageSize');

		search(query.toString(), searchCategory).then(res => {
			console.log("Search Result Page : ---->", query.toString(), searchCategory, res);
			this.setState({
				results: res.results,
				totalResultCount: res.resultsCount,
				filteredResultCount: res.filteredResultCount,
				pageCount: res.pageCount,

				companyType: res.companyTypes,
				district: res.districts,
				certificationList: res.certifications,

				selectedCompanyTypes,
				selectedDistricts,
				selectedCertificates,

				sortPath: sort.split('+')[0],
				sortOrder: sort.split('+')[1],
				sortList,

				keyword: keyword.charAt(0).toUpperCase() + keyword.slice(1),
				searchCat: searchCategory,

				currentPage: page,
				pageSize
			});
		})
	}

	// ------------------------------------------------------------------------------------
	//--- Page Change Handler
	handlePageChange = (page, pageSize) => {

		let params = new URLSearchParams(window.location.search)

		params.set('page', page)
		params.set('pageSize', pageSize)

		window.history.pushState({ page: 1 }, "FAB NET | Search", `?${params.toString()}`);

		search(params.toString(), this.state.searchCat).then(res => {
			this.setState({

				results: res.results,
				totalResultCount: res.resultsCount,
				filteredResultCount: res.filteredResultCount,
				currentPage: page,
				pageSize
			});
		});
	};

	//--- Clear All filter Handler
	handleClearFilter = () => {
		let params = new URLSearchParams(window.location.search)

		params.delete('companyTypes')
		params.delete('districts')
		params.delete('certificates')
		params.set('page', '1')
		this.setState({ selectedCompanyTypes: [], selectedCertificates: [], selectedDistricts: [] })

		window.history.pushState({ page: 1 }, "FAB NET | Search", `?${params.toString()}`);
		search(params.toString(), this.state.searchCat).then(res => {
			this.setState({
				results: res.results,
				currentPage: 1,
				totalResultCount: res.resultsCount,
				filteredResultCount: res.filteredResultCount,
				pageCount: res.pageCount,
				searchQuery: "",
			});
		});
	};

	//--- Select Filter Handler
	handleFilterSelect = (paramName, filters) => {
		let params = new URLSearchParams(window.location.search)
		params.set(paramName, filters.toString())
		params.set('page', '1')
		window.history.pushState({ page: 1 }, "FAB NET | Search", `?${params.toString()}`);
		search(params.toString(), this.state.searchCat).then(res => {

			switch (paramName) {
				case "companyTypes":
					this.setState({ selectedCompanyTypes: filters });
					break;
				case "certificates":
					this.setState({ selectedCertificates: filters });
					break;
				case "districts":
					this.setState({ selectedDistricts: filters });
					break;

				default:
					break;
			}
			this.setState({
				results: res.results,
				currentPage: 1,
				totalResultCount: res.resultsCount,
				filteredResultCount: res.filteredResultCount,
				pageCount: res.pageCount,
			});
		});
	};

	//--- sort Handler
	handleSortListSelect = (sortList) => {
		// console.log(sortList);
		let params = new URLSearchParams(this.props.location.search)
		let {
			sortPath,
			sortOrder
		} = this.state;

		if (sortList.path === sortPath)
			sortOrder = sortOrder === "asc" ? "desc" : "asc";
		else {
			sortPath = sortList.path;
			sortOrder = "asc";
		}
		// console.log(sortPath, sortOrder);
		params.set('sort', `${sortPath}+${sortOrder}`)
		window.history.pushState({ page: 1 }, "FAB NET | Search", `?${params.toString()}`);
		search(params.toString(), this.state.searchCat).then(res => {
			// console.log(res);
			this.setState({
				sortPath: sortList.path,
				sortOrder: sortOrder,

				results: res.results,
				totalResultCount: res.resultsCount,
				filteredResultCount: res.filteredResultCount,
				currentPage: 1,
			});
		});
	};

	displayResults = (results) => {
		if (results == null) {
			return (
				<div>
					<Title>No Result Found</Title>
					<Text type="secondary">An internal server error occured.</Text>;
				</div>
			)
		}
		if (results.length == 0) {
			return (
				<div>
					<Title>No Result Found</Title>
					<Text type="secondary">We're sorry. We cannot find any matches for your search keyword.</Text>;
				</div>
			)

		}
		else {
			if (this.state.searchCat == 'services' || this.state.searchCat == 'products') {
				return <ServiceProductResultCard results={results} />
			}
			else if (this.state.searchCat == 'suppliers') {
				return <SupplierResultCard results={results} />
			}
		}
	}

	render() {
		return (
			<React.Fragment>
				<MasterSearchBar
					keyword={this.getval().keyword}
					categoryId={this.getval().category}
				/>
				<Row>
					<Col span="18" offset="1">
						<Breadcrumb >
							<Breadcrumb.Item>Home</Breadcrumb.Item>
							<Breadcrumb.Item>Search</Breadcrumb.Item>
							<Breadcrumb.Item>{searchCatagories.find(x => x.value == this.getval().category).label}</Breadcrumb.Item>
						</Breadcrumb>
					</Col>
				</Row>

				<Divider />

				<Row style={{ marginBottom: 20 }}>
					<Col span="4">
						{/* <Divider /> */}
						<Title level={4}>¤ Sort</Title>
						{/* {console.log(this.state)} */}
						<SortListGroup
							items={this.state.sortList}
							selectedOrder={this.state.sortOrder}
							selectedPath={this.state.sortPath}
							onItemSelect={this.handleSortListSelect}
						/>

						<Divider />

						<Title level={4}>
							¤ Filters{" "}
							<Button type="link" onClick={this.handleClearFilter}>clear all</Button>
						</Title>

						<SearchFilter
							title="Company Type"
							items={this.state.companyType}
							selectedItems={this.state.selectedCompanyTypes}
							onItemSelect={this.handleFilterSelect.bind(this, 'companyTypes')}
						/>
						<Divider />

						<SearchFilter
							title="District"
							items={this.state.district}
							selectedItems={this.state.selectedDistricts}
							onItemSelect={this.handleFilterSelect.bind(this, 'districts')}
						/>

						<Divider />
						<SearchFilter
							title="Certification"
							items={this.state.certificationList}
							selectedItems={this.state.selectedCertificates}
							onItemSelect={this.handleFilterSelect.bind(this, 'certificates')}
						/>

					</Col>

					<Col span="20" offset="0">
						<Divider type="vertical" orientation='left' />
						{/* <Title>{this.state.searchCat}</Title> */}
						{/* {console.log(this.state)} */}
						<p>Showing {this.state.filteredResultCount} {searchCatagories.find(x => x.value == this.getval().category).label}  filtered from {this.state.totalResultCount} results found for keyword {this.state.keyword}.</p>
						<Row justify='end'>
							<Pagination
								size="small"
								responsive={true}
								total={this.state.filteredResultCount}
								current={this.state.currentPage}
								onChange={this.handlePageChange}
								showSizeChanger
								pageSize={this.state.pageSize}
								defaultPageSize={10}
								pageSizeOptions={[1, 2, 3, 10]}
								onShowSizeChange={this.handlePageChange}
							/>
						</Row>
						<Row>
							{this.displayResults(this.state.results)}
						</Row>

						<Row justify='center'>
							<Pagination
								size="small"
								total={this.state.filteredResultCount}
								current={this.state.currentPage}
								pageSize={this.state.pageSize}
								onChange={this.handlePageChange}
							/>
						</Row>
					</Col>
				</Row>
			</React.Fragment>
		);
	}



}