import React, { Component } from "react";
import ReactDOM from "react-dom";
import { LinkedIn } from "react-linkedin-login-oauth2";
import Axios from "axios";
import "./auth.css";
import "./sociallogin.css";
import { LinkedinOutlined } from "@ant-design/icons";

class LinkedInPage extends Component {
  state = {
    code: "",
    errorMessage: "",
  };

  handleSuccess = (data) => {
    this.setState({
      code: data.code,
      errorMessage: "",
    });
  };

  handleFailure = (error) => {
    this.setState({
      code: "",
      errorMessage: error.errorMessage,
    });
  };

  render() {
    const { code, errorMessage } = this.state;
    return (
      <div>
        <LinkedIn
          clientId="86b0ytlx728a87" //"86ll38knvumair"
          onFailure={this.handleFailure}
          onSuccess={this.handleSuccess}
          redirectUri="http://localhost:3000/linkedin"
          redirectPath="/linkedin"
          scope="r_emailaddress"

          renderElement={({ onClick, disabled }) => (
            <button
              className="linkedin-button"
              onClick={onClick}
              disabled={disabled}
            >
              <LinkedinOutlined className="social-media-logo" />
              Login with LinkedIn
            </button>
          )}
        />
      </div>
    );
  }
}

export default LinkedInPage;
