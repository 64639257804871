import React from "react";
// import "./auth.css";
import axios from "axios";
import { Form, Input, Button, Row, Col, Checkbox, Divider, Typography, Card } from "antd";

import { userService } from "../services";
import { values } from "lodash";
import { Link } from "react-router-dom";
const { Text, Title } = Typography

export class RegisterComponent extends React.Component {
  state = {
    values: [],
  };
  formItemLayout = {
    labelCol: {
      xs: {
        span: 24,
        offset: 0
      },
      sm: {
        span: 8,
        offset: 0
      }
    },
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0
      },
      sm: {
        span: 16,
        offset: 0
      }
    }
  };
  tailFormItemLayout = {
    wrapperCol: {
      xs: {
        span: 24,
        offset: 0
      },
      sm: {
        span: 24,
        offset: 8
      }
    }
  };

  onChange = (event) => {
    this.setState({ name: event.target.value });
  };

  onFinish = (values) => {
    console.log("Recieved value from: ", values);

    const user = {
      email: values.email,
      password: values.password,
      firstName: values.first_name,
      lastName: values.last_name,
    };

    console.log(user)
    userService.createUser(user)
      .then(result => {
        console.log(result)
        if (!result.isSuccess) {
          this.props.toastManager.add(result.message, { appearance: 'error', autoDismiss: true });
        }
        else {
          this.props.toastManager.add("Suppliyer Create Success ", { appearance: 'success', autoDismiss: true });
          this.props.history.push("/login");
        }
      });
  };

  onLoginClick = () => {
    window.location.href = `/login`;
  }

  render() {
    return (
      <div className="register-page">
        <Row>
          <Col span={12} offset={6}>
            <div style={{ marginTop: "30px" }}>
              <div onClick={() => this.props.history.push("/")}>
                {/* <img src="/images/FabLogo2.png" width="350" alt="FabLanka Logo" /> */}
                <Title style={{ fontSize: '4em', marginBottom: 5, color: "white", cursor: "pointer" }}>FABNET</Title>
              </div>
              <Card
                title="Register"
                headStyle={{ fontSize: '3em', textAlign: 'center' }}
              >
                <Form
                  name="registration_form"
                  className="registration-form"
                  initialValues={{}}
                  // labelCol={{ span: 8 }}
                  // wrapperCol={{ span: 16 }}
                  onFinish={this.onFinish}
                  {...this.formItemLayout}
                  scrollToFirstError
                >

                  <Form.Item
                    label="Name"
                    rules={[{ required: true }]}
                    style={{ marginBottom: 0 }}
                  >
                    <Form.Item
                      name="first_name"
                      rules={[{ required: true }]}
                      style={{ display: "inline-block", width: "calc(50% - 8px)" }}
                    >
                      <Input placeholder="First Name" />
                    </Form.Item>
                    <Form.Item
                      name="last_name"
                      rules={[{ required: true }]}
                      style={{
                        display: "inline-block",
                        width: "calc(50% - 8px)",
                        margin: "0 8px"
                      }}
                    >
                      <Input placeholder="Last Name" />
                    </Form.Item>
                  </Form.Item>

                  <Form.Item
                    name="email"
                    label="E-mail"
                    rules={[
                      {
                        type: "email",
                        message: "The input is not valid E-mail!"
                      },
                      {
                        required: true,
                        message: "Please input your E-mail!"
                      }
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="password"
                    label="Password"
                    rules={[
                      {
                        required: true,
                        message: "Please input your password!"
                      }
                    ]}
                    hasFeedback
                  >
                    <Input.Password />
                  </Form.Item>

                  <Form.Item
                    name="confirm"
                    label="Confirm Password"
                    dependencies={["password"]}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: "Please confirm your password!"
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue("password") === value) {
                            return Promise.resolve();
                          }

                          return Promise.reject(
                            "The two passwords that you entered do not match!"
                          );
                        }
                      })
                    ]}
                  >
                    <Input.Password />
                  </Form.Item>

                  <Form.Item
                    name="agreement"
                    valuePropName="checked"
                    rules={[
                      {
                        validator: (_, value) =>
                          value
                            ? Promise.resolve()
                            : Promise.reject("Should accept agreement")
                      }
                    ]}
                    {...this.tailFormItemLayout}
                  >
                    <Checkbox>
                      I have read the <a href="">agreement</a>
                    </Checkbox>
                  </Form.Item>

                  {/* <Form.Item
                  // {...this.tailFormItemLayout}
                  style={{ textAlign: "center" }}
                > */}
                  <Row>
                    <Col span={24} style={{ textAlign: "center" }}>
                      <Button block style={{ width: "50%" }} type="primary" htmlType="submit"> Register</Button>
                    </Col>
                  </Row>
                  {/* <br />
                  <Text type="secondary">Have an account? </Text>
                  <Link to={"/login"}>Sign in.</Link> */}
                  {/* </Form.Item> */}
                </Form>


                <Row>
                  <Col span={24} style={{ textAlign: "center" }}>
                    <Divider orientation="center" className="divider-h ">Have an account?</Divider>
                    {/* <Link to={"/register"}>Register</Link> */}
                    <Button block onClick={this.onLoginClick} style={{ width: "50%" }}>Sign in</Button>
                  </Col>
                </Row>
              </Card>
            </div>
          </Col>
        </Row>
      </div >
    );
  }
}

export default RegisterComponent;
