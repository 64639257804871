import React, { Component } from "react";
import 'antd/dist/antd.css';
import { Card, Typography, Space } from "antd";
import { Link } from "react-router-dom";
// import { Image } from 'antd';


const { Text, Paragraph, Title } = Typography;

class ServiceProductResultCard extends Component {
    render() {
        const { results } = this.props;
        return (
            <div>
                {
                    results.map((service, index) => (
                        <Space
                            key={index}
                        >
                            <Card
                                hoverable
                                style={{ width: 300, height: 250 }}
                                key={service.id}
                                title={

                                    <Space>
                                        <img src={service.company.logo} alt="logo" width="50" />
                                        <Link to={`/suppliers/${service.company.id}`}>
                                            {service.company.name}
                                            {service.verified ? <Text code>√ Verified</Text> : ""}
                                        </Link>
                                    </Space>
                                }
                            >
                                <Title level={4}>{service.title}</Title >
                                <Paragraph ellipsis={{ rows: 5, expandable: true, symbol: 'more' }}>{service.description}</Paragraph>
                            </Card>
                            <br />
                        </Space>

                    ))
                }
            </div>
        );
    }
}

export { ServiceProductResultCard };


