import React from 'react'
import PropTypes from 'prop-types'

import {
  Row,
  Col,
  Menu,
  Dropdown,
  Alert,
  Typography,
  Input,
  Select,
  Card,
} from "antd";
import { DownOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { MasterSearchBar } from '../../components/MasterSearchBar'

import "./landingPage.css";

const { Search } = Input;
const { Option } = Select;
const { Title, Text } = Typography


const LandingPage = () => {
  return (
    <div className="landing-page">
      <div style={{
        padding: "75px 0",
        background: "#FFF"
      }}>
        <div className="Content-logo">
          <Row>
            <Col span="24" offset="0" align="center">
              <Title style={{ fontSize: '4em', marginBottom: 5 }}>FABNET</Title>
              <Text level={4} style={{ fontSize: '1.5em' }}>
                For Suppliers <span className='subtitleSeparator'>|</span> KnowledgeBase <span className='subtitleSeparator'>|</span> Insights, Tools, and More...

              </Text>
            </Col>
          </Row>
        </div>
        <div >
          <Row>
            <Col span="24" offset="0" align="center">
              <h1>Bring Your Ideas to life</h1>
            </Col>
          </Row>
        </div>
        <div className="Content-SearchBar">
          <MasterSearchBar />
        </div>
        <div className="Content-KnowledgeBase">
          <Row justify='center'>
            <Col span="12" align="center">
              <a href="/knowledge-base">Engineering Knowledge Base</a>
            </Col>
          </Row>
        </div>
      </div>

      <div className="Content-Highlights">
        <Row justify='space-around'>
          <Col align="center">
            <Card >
              <img src='/images/Highlight.png' alt="FabLanka Logo" width="60%"></img>;
              <h2>Highlight 01</h2>
              <p>Card content</p>
            </Card>
          </Col>
          <Col span="6" offset="0" align="center">
            <Card >
              <img src='/images/Highlight.png' alt="FabLanka Logo" width="60%"></img>;
              <h2>Highlight 02</h2>
              <p>Card content</p>
            </Card>
          </Col>
          <Col span="6" offset="0" align="center">
            <Card >
              <img src='/images/Highlight.png' alt="FabLanka Logo" width="60%"></img>;
              <h2>Highlight 03</h2>
              <p>Card content</p>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
}

LandingPage.propTypes = {

}

export { LandingPage }


