import React, { Component } from "react";
import {
    Table,
    Space,
    Button,
    Popconfirm,
    Divider,
    Typography,
    Tabs,
    Card, Tag, List,
    Form, Input, Upload, Col, Row
} from 'antd';
import { withToastManager } from "react-toast-notifications";
import { FacebookFilled, TwitterSquareFilled, LinkedinFilled, UploadOutlined,EyeOutlined } from '@ant-design/icons';

import { rfiService, userService } from "../../../services";
import { FormData } from 'form-data';

// import "./rfiListPage.css"
const { Text, Title, Paragraph, Link } = Typography;
const { TabPane } = Tabs;
const layout = {
    labelCol: { span: 0 },
    wrapperCol: { span: 16 },
};

const validateMessages = {
    required: '${label} is required!',
    types: {
        email: '${label} is not a valid email!',
        number: '${label} is not a valid number!',
    },
    number: {
        range: '${label} must be between ${min} and ${max}',
    },
};

class RfiViewPageComponent extends Component {


    constructor(props) {
        super(props);
        this.state = {
            rfi: {
                id: "",
                title: "",
                message: "",
                responseNeededBy: "",
                createdDate: "",
                supplierCompany: {},
                buyerCompany: {},
                sender: {},
                recipient: "",
                attachments: "",
                status: "",
                response: "",
                responseAttchment: "",
                respondedDate: "",
                respondedBy: "",
            },
            fileList: [],
            uploading: false

        };
    }

    componentDidMount() {
        this.fetchData();
    }

    fetchData = async () => {
        let id = this.props.match.params.id;
        rfiService.GetRFI(id)
            .then(result => {
                if (result.isSuccess) {
                    let rfi = result.dto;
                    this.setState({ rfi });
                    if (rfi.status == "Unread") {
                        this.changeStatus(id, "Read")
                    }
                    let responseNeededBy = new Date(rfi.responseNeededBy)
                    let now = new Date()
                    console.log(rfi.respondedDate);
                    console.log(responseNeededBy, now, responseNeededBy < now);
                    if (rfi.respondedDate == null && (responseNeededBy < now))
                        this.changeStatus(id, "Expiered")

                }
                else {
                    this.props.toastManager.add(result.message, { appearance: 'error', autoDismiss: true });
                }
            });
    }

    changeStatus = (id, status) => {
        console.log(id)
        rfiService.changeStatus(id, status).then(result => {
            if (result.isSuccess) {
                this.props.toastManager.add(result.message, { appearance: 'success', autoDismiss: true });
                // this.fetchReceivedRFIs();
                let rfi = this.state.rfi;
                rfi.status = "Read"
                this.setState(rfi)
            } else {
                this.props.toastManager.add(result.message, { appearance: 'error', autoDismiss: true });
            }
        })
    }

    getAttachmentList = (files) => {
        console.log(files);
        if (files) {
            let fileList = [];
            {
                files.map((url, index) => {
                    console.log(url, index);
                    let fileName = url.split("/").pop()
                    fileName = fileName.split("___").pop()
                    fileName = fileName.split("_res_").pop()
                    console.log(fileName);
                    fileList.push({ name: fileName, url: url })
                })
            }
            return (
                <List                    
                    dataSource={fileList}
                    renderItem={item => (
                        <List.Item
                            actions={[<a href={item.url}><EyeOutlined /></a>]}
                        >
                            <List.Item.Meta
                                title={<a href={item.url}>{item.name}</a>}
                                // description=""
                            />
                        </List.Item>
                    )}
                />
            )
        } else {
            return (<Text>No attachments.</Text>);
        }
    }

    loadResponse = (status) => {
        let rfi = this.state.rfi;
        if (status == "Unread" || status == "Read") {
            const { uploading, fileList } = this.state;
            const uploaderProps = {
                onRemove: file => {
                    this.setState(state => {
                        const index = state.fileList.indexOf(file);
                        const newFileList = state.fileList.slice();
                        newFileList.splice(index, 1);
                        return {
                            fileList: newFileList,
                        };
                    });
                },
                beforeUpload: file => {
                    this.setState(state => ({
                        fileList: [...state.fileList, file],
                    }));
                    return false;
                },
                fileList,
            };
            return (<Form {...layout} name="nest-messages" onFinish={this.onFinish} validateMessages={validateMessages}>
                <Form.Item name={['response', 'message']} label="Message" rules={[{ required: true }]} >
                    <Input.TextArea placeholder={rfi.response} rows={5} value={rfi.response} />
                </Form.Item>

                <Form.Item>
                    <Upload {...uploaderProps}>
                        <Button icon={<UploadOutlined />}>Select File</Button>
                    </Upload>
                </Form.Item>

                <Form.Item wrapperCol={{ ...layout.wrapperCol }}>
                    <Button type="primary" htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </Form>)
        }
        else {
            return (
                <>
                    <Row gutter={[24, 8]}>
                        <Col span={3} ><Text strong>Responded on: </Text></Col>
                        <Col span={12} ><Text>{new Date(rfi.respondedDate).toLocaleDateString('en-AU')}</Text></Col>
                    </Row>
                    <Row gutter={[24, 8]}>
                        <Col span={3} ><Text strong>Responded By: </Text></Col>
                        <Col span={12} >
                            <Text>{rfi.respondedBy.firstName + " " + rfi.respondedBy.lastName + " ("}</Text>
                            <Link>{rfi.respondedBy.userName} </Link>
                            <Text>)</Text>
                        </Col>
                    </Row>
                    <Row gutter={[24, 8]}>
                        <Col span={3} ><Text strong>Message: </Text></Col>
                        <Col span={12} ><Text>{rfi.response}</Text></Col>
                    </Row>
                    <Row gutter={[24, 8]}>
                        <Col span={3} ><Text strong>Attachments: </Text></Col>
                        <Col span={8} >  {this.getAttachmentList(rfi.responseAttchment)}</Col>
                    </Row>
                </>
            )
        }
    }


    onFinish = (values) => {
        const { fileList } = this.state;
        this.setState({
            uploading: true
        });
        // console.log(values, fileList, formData);
        // let response = { message: values.response.message, fileList: fileList }
        // console.log(response);
        rfiService.SendRespond(this.state.rfi.id, values.response.message, fileList)
    };

    getHeader = (title, status) => {
        return (<Space>
            <Text strong>{title}</Text>
            <Tag color={status == "Pendding" ? "orange" : status == "Unread" ? "blue" : status == "Read" ? "purple" : status == "Responded" ? "green" : "red"}>{status}</Tag>
        </Space>)

    }


    render() {
        let rfi = this.state.rfi;

        return (
            <div style={{ padding: "10px" }}>
                <Card
                    title={this.getHeader(rfi.title, rfi.status)}
                >
                    <Divider orientation="left">Request</Divider>

                    <Row gutter={[24, 8]}>
                        <Col span={3} ><Text strong>Requesed on: </Text></Col>
                        <Col span={12} ><Text>{new Date(rfi.createdDate).toLocaleDateString('en-AU')}</Text></Col>
                    </Row>
                    <Row gutter={[24, 8]}>
                        <Col span={3} ><Text strong>Response Needed By: </Text></Col>
                        <Col span={12} ><Text>{new Date(rfi.responseNeededBy).toLocaleDateString('en-AU')}</Text></Col>
                    </Row>
                    <Row gutter={[24, 8]}>
                        <Col span={3} ><Text strong>Requested By:  </Text></Col>
                        <Col span={12} >
                            <Text>{rfi.sender.firstName + " " + rfi.sender.lastName + " ("}</Text>
                            <Link>{rfi.sender.userName} </Link>
                            <Text>)</Text>
                            <Link>{/* { rfi.buyerCompany } */}</Link>
                        </Col>
                    </Row>
                    <Row gutter={[24, 8]}>
                        <Col span={3} ><Text strong>Message: </Text></Col>
                        <Col span={12} ><Text>{rfi.message}</Text></Col>
                    </Row>
                    <Row gutter={[24, 8]}>
                        <Col span={3} ><Text strong>Attachments: </Text></Col>
                        <Col span={8} >{this.getAttachmentList(rfi.attachments)}</Col>
                    </Row>

                    <Divider orientation="left">Respond</Divider>

                    <div>
                        {
                            this.loadResponse(rfi.status)
                        }

                    </div>
                </Card>
            </div>
        );
    }
}

export const ViewRFIPage = withToastManager(RfiViewPageComponent);