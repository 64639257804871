import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import "./fixedHeaderLayout.css";

import { Layout, Menu, Row, Alert, Col, Button, Space, Avatar, Dropdown } from "antd";
import { authService } from "../../services";
import {
    TeamOutlined,
    UserOutlined,
    SettingOutlined,
    ShopOutlined,
    HomeOutlined,
    DashboardOutlined,
    DeploymentUnitOutlined,
    UserSwitchOutlined,
    LogoutOutlined,
    FileTextOutlined,
    FileExclamationOutlined

} from "@ant-design/icons";

// import { Navbar } from "./navbar";
// import { SideBarMenu } from "../components/SidebarMenu";

const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

class LayoutClass extends Component {

    constructor(props) {
        super(props);
        this.state = {
            sideMenuCollpsed: false,
            currentUser: null,
        };
    }

    onCollapse = (sideMenuCollpsed, type) => {
        this.setState({ sideMenuCollpsed });
    };


    handleLogout = () => {
        authService.logout();
        this.props.history.push("/login");
    };

    isLoggedIn = () => {
        const currentUser = authService.currentUserValue;
        this.state.currentUser = currentUser
        return !!currentUser && !!currentUser.token;
    };

    getRoles = () => {
        let roles = authService.currentUserValue?.roles;
        return roles;
    }

    hasPermission = (authorize) => {
        const currentUser = authService.currentUserValue;
        if (authorize) {
            if (currentUser) {
                return authService.currentUserValue.permissions[authorize];
            } else {
                return false
            }
        } else {
            return true
        }
    };


    ProfileDropdownMenu = (
        <Menu/*  theme="dark" */>
            <Menu.Item>
                <Button type="link"
                    onClick={() => this.props.history.push('/profile/userData')}
                    icon={<UserSwitchOutlined />}>
                    Profile
                </Button>
            </Menu.Item>
            <Menu.Item>
                <Button type="link"
                    onClick={this.handleLogout}
                    icon={<LogoutOutlined />}>
                    Log Out
                </Button>
            </Menu.Item>
        </Menu>
    );


    menuProductService = [
        <Menu.Item key="prod-service-1">
            <a
                rel="noopener noreferrer"
                onClick={() => this.props.history.push("/services")}
            >
                Services
            </a>
        </Menu.Item>,
    ];

    menuIndustry = [
        <Menu.Item key="industry-0">
            <a
                rel="noopener noreferrer"
                onClick={() => this.props.history.push("/industries/metal")}
            >
                Metal Industry
            </a>
        </Menu.Item>,
        <Menu.Item key="industry-1">
            <a
                rel="noopener noreferrer"
                onClick={() => this.props.history.push("/industries/rubber")}
            >
                Rubber Industry
            </a>
        </Menu.Item>,
        <Menu.Item key="industry-2">
            <a
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => this.props.history.push("/industries/plastics")}
            >
                Plastic Industry
            </a>
        </Menu.Item>,
    ];

    menuMarketPlace = [
        <Menu.Item key="market-0">
            <a rel="noopener noreferrer" href="#">
                Mechanical Items
            </a>
        </Menu.Item>,
        <Menu.Item key="market-1">
            <a rel="noopener noreferrer" href="#">
                Electronic Items
            </a>
        </Menu.Item>,
    ];

    menuEducationInnovation = [
        <Menu.Item key="ed-inno-0">
            <a
                rel="noopener noreferrer"
                onClick={() => this.props.history.push("/education/seminars")}
            >
                Seminars
            </a>
        </Menu.Item>,
        <Menu.Item key="ed-inno-10">
            <a
                rel="noopener noreferrer"
                onClick={() => this.props.history.push("/education/workshops")}
            >
                Workshops
            </a>
        </Menu.Item>,
    ];


    render() {
        const { sideMenuCollpsed } = this.state;
        return (

            <Layout style={{ minHeight: '100vh' }} className="site-layout">

                <Header className="header" style={{ position: 'fixed', zIndex: 1, width: '100%' }} >
                    <div
                        style={{ lineHeight: "60px" }}
                        className="logo"
                        onClick={() => this.props.history.push("/")}
                    >
                        <img src="/images/FabLogo1.png" alt="FabLanka Logo" height="60" />
                    </div>

                    <Row>
                        <Col span={20} style={{ lineHeight: "60px" }}>
                            <Menu mode="horizontal" defaultSelectedKeys={["1"]} className="header-menu">
                                <SubMenu title="Product & Service Intergration">
                                    {this.menuProductService}
                                </SubMenu>
                                <SubMenu key="for-industry" title="For Industry">
                                    {this.menuIndustry}
                                </SubMenu>
                                <SubMenu key={2} title="Market Place">
                                    {this.menuMarketPlace}
                                </SubMenu>
                                <SubMenu key={3} title="Education / Inovation">
                                    {this.menuEducationInnovation}
                                </SubMenu>
                            </Menu>
                        </Col>
                        <Col
                            span={4}
                            style={{ position: "fixed", right: '10px' }}
                        >
                            {(!this.isLoggedIn() && (
                                <Space style={{ position: "absolute", right: 10, margin: "auto" }}>
                                    <Button
                                        type="primary"
                                        onClick={() => this.props.history.push("/login")}
                                    >
                                        Log In
                                    </Button>
                                    <Button onClick={() => this.props.history.push("/register")}>
                                        Register
                                    </Button>
                                </Space>
                            )) || (
                                    <Dropdown
                                        overlay={this.ProfileDropdownMenu}
                                        arrow
                                        placement="bottomRight"
                                        overlayClassName="acc-dropdown"
                                    >
                                        <Button
                                            style={{ color: '#242425' }}
                                            className="avatar-btn"
                                            type="link"
                                            onClick={() => this.props.history.push(`/profile/userData`)}
                                        >
                                            {this.state.currentUser.avatar &&
                                                <Avatar src={this.state.currentUser.avatar} />
                                            }
                                            {!this.state.currentUser.avatar &&
                                                <Avatar>{this.state.currentUser.firstName!=null?this.state.currentUser.firstName.charAt(0):""}</Avatar>
                                            }
                                            <span> {this.state.currentUser.firstName}</span>
                                        </Button>
                                    </Dropdown>
                                )}
                        </Col>
                    </Row>
                </Header>


                <Content className="site-layout-content">
                    <Layout className="site-layout-background" >

                        {this.props.showMessage && (
                            <div className="Header-Message">
                                <Row>
                                    <Col span="24" offset="0" align="center">
                                        <Alert
                                            message="Situational Special Messages and Links"
                                            type="info"
                                            closable
                                        />
                                    </Col>
                                </Row>
                            </div>
                        )}

                        <Content className="main-content-area">
                            {this.props.children}
                        </Content>

                    </Layout>
                </Content>

                <Footer className="footer">
                    FabLanka ©2020
                </Footer>


            </Layout>
        );
    }
}
export const FixedHeaderLayout = withRouter(LayoutClass);
