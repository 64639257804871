import React, { Component } from "react";
import ReactDOM from "react-dom";
import FacebookLogin from "react-facebook-login";
import Axios from "axios";
import "./auth.css";
import "./sociallogin.css";
import { FacebookOutlined } from "@ant-design/icons";


export class facebookButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoggedIn: false,
      userID: "",
      name: "",
      email: "",
      accessToken: "",
    };

    this.responseFacebook = this.responseFacebook.bind(this);
  }

  responseFacebook = (response) => {
    if (this.state.isLoggedIn) {
      this.context.history.push("/dashboard");
    } else if (response.accessToken) {
      console.log(response);
      this.setState({
        isLoggedIn: true,
        userID: response.userID,
        name: response.name,
        email: response.email,
        picture: response.picture.data.url,
      });
      console.log(this.state);

      Axios.post("", [response]).then((result) => {
        this.props.history.push("/Dashboard");
      });
    } else {
      this.setState({
        isLoggedIn: false,
      });
      // this.context.history.push("/login");
    }
  };

  render() {
    return (



      <div >
        <FacebookLogin
          renderElement={({ onClick, disabled }) => (
            <button
              className="facebook-button"
              onClick={onClick}
              disabled={disabled}
            >
              <FacebookOutlined className="social-media-logo" />
           Login with Facebook
            </button>
          )}
          //   render={(renderProps) => (
          //     <button
          //       className="google-button"
          //       onClick={renderProps.onClick}
          //       disabled={renderProps.disabled}
          //     >
          //       <FacebookOutlined className="social-media-logo" />
          // Login with Google
          //     </button>
          //   )}

          appId="3084265728357658"
          autoLoad={false}
          textButton="    Login with Facebook"
          fields="name,email,picture"
          callback={this.responseFacebook}
          cssClass="facebook-button"
        />
      </div>
    );
  }
}

export default facebookButton;
