import React, { Component } from "react";
import "./auth.css";
import {
    Form,
    Input,
    Button,
    Row,
    Col,
    Card,
    PageHeader,
    Typography,
    Space,

} from "antd";
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Redirect } from "react-router-dom";
const { Text, Title } = Typography


const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 8,
    },
}
export class ResetPasswordComponent extends Component {

    constructor(props) {
        super(props);
        this.state = { username: 'Username' };
    }

    onSubmit = (values) => {
        if (!values.confirm_Password || !values.new_Password) return;
        if (values.new_Password !== values.confirm_Password) {
            console.log('passwords are not matching')
        } else {
            // console.log('passwords are matching')
            return <Redirect to={'/login'} />
        }
    };

    onFinishFailed = (errorInfo) => {
        console.log("Failed:", errorInfo);
    };


    render() {
        return (

            <React.Fragment>
                <Row><br /></Row>
                <Row
                    type="flex"
                    justify="center"
                    align="middle"
                >
                    <Col span="12" >
                        <Card>
                            <PageHeader
                                className="site-page-header-responsive"
                                title="Change Password"
                            />
                            <Title level={5}>Change password for {this.state.username}. </Title>
                            <br />
                            <Form
                                align="middle"
                                name="basic"
                                initialValues={{
                                    remember: true,
                                }}
                                onFinish={this.onSubmit}
                                onFinishFailed={this.onFinishFailed}
                            >

                                <Form.Item
                                    {...layout}
                                    label="New Password"
                                    name="new_Password"
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please input new password.",
                                        },
                                    ]}
                                >
                                    <Input.Password
                                        iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                    />
                                </Form.Item>

                                <Form.Item
                                    {...layout}
                                    label="Confirm Password"
                                    name="confirm_Password"
                                    dependencies={['password']}
                                    hasFeedback
                                    rules={[
                                        {
                                            required: true,
                                            message: "Please repeat password to confirm.",
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(rule, value) {
                                                if (!value || getFieldValue('new_Password') === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject('The two passwords that you entered do not match!');
                                            },
                                        }),
                                    ]}
                                >
                                    <Input.Password
                                        iconRender={visible => (visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />)}
                                    />
                                </Form.Item>
                                    <Text type="secondary">
                                        Make sure it's at least 8 characters including a number and a lowercase letter.
                                    </Text>
                                    <br />
                                <Form.Item >
                                    <Button type="primary" htmlType="submit">Reset Password</Button>
                                </Form.Item>
                            </Form>
                        </Card>

                    </Col>
                </Row>
            </React.Fragment>
        );
    }
}