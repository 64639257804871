import axios from '../config/API';
import { trackPromise } from 'react-promise-tracker';

export const SearchService = {
    searchServices,
    search,
    searchProducts
};


export async function searchServices(param) {
    try {
        const result = await trackPromise(axios.get(`/Search/Service?${param}`));
        const data = result.data;
        if (data.isSuccess) {
            return data.dto;
        }
    }
    catch (err) {
        console.error(err)
        return { isSuccess: false, message: "Unable to get search result." };
    }
}

export async function searchProducts(param) {
    try {
        const result = await trackPromise(axios.get(`/Search/Products?${param}`));
        const data = result.data;
        if (data.isSuccess) {
            return data.dto;
        }
    }
    catch (err) {
        console.error(err)
        return { isSuccess: false, message: "Unable to get search result." };
    }
}

export async function search(param, category) {
    console.log(category);
    try {
        const result = await trackPromise(axios.get(`/Search/${category}?${param}`));
        const data = result.data;
        if (data.isSuccess) {
            return data.dto;
        }
    }
    catch (err) {
        console.error(err)
        return { isSuccess: false, message: "Unable to get search result." };
    }
}