import React, { Component } from "react";
import { database } from "../firebase";
import _ from "lodash";


import {
  Row,
  Col,
  Button,
  Breadcrumb,
  Card,
} from "antd";

import Doc from "./DocService";
import PdfContainer from "./PdfContainer";

import InvoiceRender from "./invoiceRender";

class InvoiceDetails extends Component {
  state = { invoices: "", invoiceId: "", invoice: "" };

  componentDidMount() {
    database.ref("/invoices").on("value", (snapshot) => {
      this.setState({
        invoices: snapshot.val(),
      });
    });

    // get post ID from the URL and set it in state.postId
    const invoiceId = this.props.match.params.id;
    this.setState({ invoiceId });
  }

  createPdf = (html) => Doc.createPdf(html);

  onInvoiceHomeClick = () => {
    const { history } = this.props;
    if (history) history.push("/invoice");
  };

  renderPost() {
    return _.map(this.state.invoices, (invoice) => {
      if (invoice._id === this.state.invoiceId) {
        return (
          <Card>
            <InvoiceRender
              invoiceId={invoice.invoiceId}
              customerName={invoice.customerName}
              billingAddress={invoice.billingAddress}
              invoiceDate={invoice.invoiceDate}
              invoiceDetails={invoice.invoiceDetails}
              taxRate={invoice.taxRate}
              notes={invoice.notes}
              subTotal={invoice.subTotal}
              taxTotal={invoice.taxTotal}
              total={invoice.total}
              currencySym={invoice.currencySym}
            />
          </Card>
        );
      }
    });
  }

  render() {
    return (
      <div className="container">
        <Row>
          <Col span="18" offset="3">
            <Breadcrumb style={{ marginBottom: 10 }}>
              <Breadcrumb.Item>Home</Breadcrumb.Item>
              <Breadcrumb.Item>Supper-Customer Name</Breadcrumb.Item>
              <Breadcrumb.Item>Invoice</Breadcrumb.Item>
              <Breadcrumb.Item>{this.state.invoiceId}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Row>
          <Col span="3" offset="3">
            <Button type="text" onClick={this.onInvoiceHomeClick}>
              Invoice Home         
            </Button>
          </Col>
          <Col span="12" offset="0">
            <br />

            <PdfContainer createPdf={this.createPdf}>
              {this.renderPost()}
            </PdfContainer>
            <br />
          </Col>
        </Row>
      </div>
    );
  }
}

export default InvoiceDetails;
