import React, { Component, useState } from "react";
import _ from "lodash";
import {
    Row,
    Col,
    Menu,
    Dropdown,
    Alert,
    Typography,
    Input,
    Select,
    Card,
} from "antd";
import { useHistory } from "react-router-dom";
import "./masterSeachBarStyle.css"

import { searchCatagories } from "./seachCategories"


const { Search } = Input;
const { Option } = Select;
const { Title } = Typography;



const MasterSearchBar = ({ keyword, categoryId }) => {
    console.log("===>  key:", keyword, "catagory:", categoryId);
    let category = searchCatagories.find(val => val.value == categoryId);
    console.log("categoty:", category);
    const [searchType, setSearchType] = useState(categoryId ? category : { value: "services", label: "Professional Services" });
    // const [searchType, setSearchType] = useState({ id: "services", value: "Professional Services" });
    const history = useHistory();

    function onSelectSearchType(value, option) {
        setSearchType(option);
    }

    function handleSearchClick(newKeyWord) {
        // console.log(`val`, newKeyWord, searchType)
        if (!newKeyWord || !searchType.value) return;

        history.push(`/search?category=${searchType.value}&keyword=${newKeyWord}`);
        history.go()
    }

    const selectBefore = (
        <Select
            onChange={onSelectSearchType}
            defaultValue={searchType.value}
            className="masterSearchBar"
            size='large'
            style={{ borderRadius: "5px 0px 0px 5px", width: "200px" }}
            options={searchCatagories}
        />
    );

    return (
        <div>
            <Input.Group size='large'>
                <Row justify='center'>
                    <Col span={16}>
                        {selectBefore}
                        <Search
                            allowClear
                            placeholder={"Search for " + searchType.label}
                            defaultValue={keyword}
                            style={{ width: '70%' }}
                            enterButton='Search'
                            size='large'
                            onSearch={handleSearchClick}
                            className="masterSearchBar"
                        />
                    </Col>
                </Row>
            </Input.Group>
        </div>
    )
};

export { MasterSearchBar };