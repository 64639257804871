import React from 'react';
import { Redirect, Route, withRouter } from "react-router-dom";
import { authService } from "../../services";

const hasPermission = (authorize) => {
    const currentUser = authService.currentUserValue;
    console.log("CURRENT USER::::>>>", currentUser)
    if (authorize) {
        if (authorize == 'isLogged') {
            return currentUser != null;
        }
        if (currentUser) {
            if (authService.currentUserValue.permissions) {
                return authService.currentUserValue.permissions[authorize];
            } else {
                return false
            }
        } else {
            return false
        }
    } else {
        return true
    }
};

const RouterComponent = ({ page: Component, authorize: authorize, layout: Layout, ...rest }) => {
    return (
        <Route
            {...rest}
            render={({ ...routeProps }) =>
                hasPermission(authorize) ? (
                    <Layout>
                        <Component {...routeProps} />
                    </Layout>
                ) : (
                    <Redirect
                        to={{
                            pathname: '/login',
                            state: { from: routeProps.location }
                        }}
                    />
                )
            }
        />
    );
};


export default withRouter(RouterComponent);