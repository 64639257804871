import React, { Component } from "react";
import {
    Table,
    Space,
    Button,
    Popconfirm,
    Divider,
    Typography,
    Tabs, Tag
} from 'antd';
import { withToastManager } from "react-toast-notifications";
import { rfqService, userService, authService } from "../../../services";
import "./rfqListPage.css"
const { Text, Title } = Typography;
const { TabPane } = Tabs;

class RFQListPageComponent extends Component {
    receivedColumns = [
        {
            title: 'Title',
            dataIndex: 'title',
            render: (text, record, index) => text,
        },
        {
            title: 'Sent By',
            dataIndex: 'sentBy',
            render: (text, record, index) => text,
        },
        {
            title: 'Requested Date',
            dataIndex: 'date',
            render: (text, record, index) => text,
            sortOrder: true
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (status) => {
                return (
                    <Tag color={status == "Pendding" ? "orange" : status == "Unread" ? "blue" : status == "Read" ? "purple" : status == "Responded" ? "green" : "red"}>{status}</Tag>
                )
            },
            sortOrder: true
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <Button size="small" type="primary" href={`/manage/rfq/${record.id}`}>View</Button>
                    {record.status == 'Unread' &&
                        <Button size="small" type="secondary" style={{ width: "112px" }} onClick={() => this.changeStatus(record.id, "Read")}>Mark as read</Button>
                    }
                    {record.status == 'Read' &&
                        <Button size="small" type="secondary" style={{ width: "112px" }} onClick={() => this.changeStatus(record.id, "Unread")}>Mark as unread</Button>
                    }
                    <Popconfirm title="Are you sure？" okText="Yes" cancelText="No" onConfirm={() => this.removeRfq(record.id)}>
                        <Button size="small" type="primary" danger href="#">Remove</Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    sentColumns = [
        {
            title: 'Title',
            dataIndex: 'title',
            render: (text, record, index) => text,
        },
        {
            title: 'Sent To',
            dataIndex: 'sentTo',
            render: (text, record, index) => text,
        },
        {
            title: 'Requested Date',
            dataIndex: 'date',
            render: (text, record, index) => text,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            render: (status) => {
                return (
                    <Tag color={status == "Pendding" ? "orange" : status == "Unread" ? "blue" : status == "Read" ? "purple" : status == "Responded" ? "green" : "red"}>{status}</Tag>
                )
            },
            sortOrder: true
        },
        {
            title: 'Action',
            key: 'action',
            render: (text, record) => (
                <Space size="middle">
                    <Button size="small" type="primary" href={`/manage/rfq/${record.id}`}>View</Button>
                    <Popconfirm title="Are you sure？" okText="Yes" cancelText="No" onConfirm={() => this.removeMember(record.id)}>
                        <Button size="small" type="primary" danger href="#">Remove</Button>
                    </Popconfirm>
                </Space>
            ),
        },
    ];

    constructor(props) {
        super(props);
        this.state = {
            company: null,
            sentRFQs: [],
            receivedRFQs: [],
            isSupplier: false,
            isAdmin: false,
        };
    }

    componentWillMount() {
        this.getPermissions();
    }

    componentDidMount() {
        this.fetchData();
    }


    fetchData = async () => {
        const query = new URLSearchParams(this.props.location.search);
        let companyId = query.get('id');
        //console.log(companyId)

        let res = await userService.getUserData();
        if (res.isSuccess && companyId == null) {
            if (res.dto.company != null) {
                this.setState({ company: res.dto.company })
                companyId = res.dto.company.id;
            }
        }

        if (companyId == null) {
            this.fetchSentRFQsByUser();
        }
        else {
            if (this.state.isSupplier) {
                this.fetchReceivedRFQs();
            }
            this.fetchSentRFQs();
        }


    }
    getPermissions = () => {
        if (authService.currentUserValue.permissions)
            this.setState({
                isSupplier: authService.currentUserValue.permissions["isSupplier"],
                isAdmin: authService.currentUserValue.permissions["isAdmin"]
            });
    }

    fetchSentRFQsByUser = async () => {
        rfqService.fetchSentRFQsByUser("00000000-0000-0000-0000-000000000000")
            .then(result => {
                if (result.isSuccess) {
                    let RFQs = result.dto;
                    let sentRFQs = new Array;
                    RFQs.forEach((RFQ, index) => {
                        sentRFQs.push({
                            id: RFQ.id,
                            key: RFQ.id,
                            title: RFQ.title,
                            sentTo: RFQ.sender.firstName + " " + RFQ.sender.lastName + " (" + RFQ.sender.email + ")",
                            date: new Date(RFQ.createdDate).toDateString(),
                            status: RFQ.status
                        });
                    });

                    this.setState({ sentRFQs });
                }
                else {
                    this.props.toastManager.add(result.message, { appearance: 'error', autoDismiss: true });
                }
            });
    }
    fetchSentRFQs = async () => {
        rfqService.fetchSentRFQs()
            .then(result => {
                if (result.isSuccess) {
                    let RFQs = result.dto;
                    // debugger
                    let sentRFQs = new Array;
                    RFQs.forEach((RFQ, index) => {
                        sentRFQs.push({
                            id: RFQ.id,
                            key: RFQ.id,
                            title: RFQ.title,
                            sentTo: RFQ.sender.firstName + " " + RFQ.sender.lastName + " (" + RFQ.sender.email + ")",
                            date: new Date(RFQ.createdDate).toDateString(),
                            status: RFQ.status
                        });
                    });

                    this.setState({ sentRFQs });
                }
                else {
                    this.props.toastManager.add(result.message, { appearance: 'error', autoDismiss: true });
                }
            });
    }
    fetchReceivedRFQs = async () => {
        rfqService.fetchReceivedRFQs()
            .then(result => {
                if (result.isSuccess) {
                    let RFQs = result.dto;
                    let receivedRFQs = new Array;
                    // debugger
                    RFQs.forEach((RFQ, index) => {
                        receivedRFQs.push({
                            id: RFQ.id,
                            key: RFQ.id,
                            title: RFQ.title,
                            sentBy: RFQ.sender.firstName + " " + RFQ.sender.lastName + " (" + RFQ.sender.email + ")",
                            date: new Date(RFQ.createdDate).toDateString(),
                            status: RFQ.status
                        });
                    });
                    //console.log(receivedRFQs, result.dto);
                    this.setState({ receivedRFQs });
                }
                else {
                    this.props.toastManager.add(result.message, { appearance: 'error', autoDismiss: true });
                }
            });

    }

    changeStatus = (id, status) => {
        //console.log(id)
        rfqService.changeStatus(id, status).then(result => {
            if (result.isSuccess) {
                this.props.toastManager.add(result.message, { appearance: 'success', autoDismiss: true });
                this.fetchReceivedRFQs();
            } else {
                this.props.toastManager.add(result.message, { appearance: 'error', autoDismiss: true });
            }
        })
    }
    removeRfq = (id) => {
        //console.log(id)
    }

    render() {
        return (
            <React.Fragment>
                <h2>Request For Quotation</h2>
                <Tabs defaultActiveKey="received" >
                    {(this.state.isSupplier) &&
                        <TabPane tab="Received" key="received">
                            {/* <h3>Sent</h3> */}
                            <Table size='small'
                                columns={this.receivedColumns}
                                dataSource={this.state.receivedRFQs}
                                bordered
                                rowKey={record => record.id}
                                rowClassName={(record) => record.status.replace('#', '')}
                            // rowClassName={"Unread"}
                            />
                        </TabPane>
                    }
                    <TabPane tab="Sent" key="sent">
                        {/* <h3>Received</h3> */}
                        <Table size='small' columns={this.sentColumns} dataSource={this.state.sentRFQs} bordered />
                    </TabPane>
                </Tabs>
            </React.Fragment>
        );
    }
}

export const RFQListPage = withToastManager(RFQListPageComponent);