const dev = {
  production: false,
  WebApiBaseUrl: "https://localhost:44336/api/v1",
};

const prod = {
  production: true,
  WebApiBaseUrl: process.env.REACT_APP_PROD_WEB_API + "/api/v1",
};

export const environment = process.env.NODE_ENV === "development" ? dev : prod;