import React from "react";
import "./auth.css";
import {
  Form,
  Input,
  Button,
  Checkbox,
  Row,
  Col,
  Card,
  PageHeader,
  Typography,
  Divider
} from "antd";

import {
  FacebookFilled,
  GoogleCircleFilled,
  LinkedinFilled,
} from "@ant-design/icons";

import Loginbygoogle from "./google-login";
import Loginbyfacebook from "./facebook-login";
import Loginbylinkedin from "./linkedin-login";
import { authService } from "../services";
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Link } from "react-router-dom";
const { Text, Title } = Typography

export class LoginComponent extends React.Component {
  // componentDidMount() {
  //   if (!!authService.currentUserValue) {
  //     this.props.history.push('/dashboard');
  //   }
  // }

  onFinish = (values) => {
    if (!values.username || !values.password) return;
    authService.login(values.username, values.password).then((result) => {
      if (!!result) {
        this.props.history.push("/dashboard");
      } else {
        this.props.toastManager.add("Invalid Email or Password", { appearance: 'error', autoDismiss: true });
      }
    });
  };

  onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  onRegisterClick() {
    window.location.href = `/register`;
  }

  render() {
    return (
      <div className="login-page">
        <Row>
          <Col span={12} offset={6}>

            <div style={{ marginTop: "30px" }}>
              <div onClick={() => this.props.history.push("/")}>
                {/* <img src="/images/FabLogo2.png" width="350" alt="FabLanka Logo" /> */}
                <Title style={{ fontSize: '4em', marginBottom: 5, color: "white", cursor: "pointer" }}>FABNET</Title>
              </div>
              <Card
                title="Sign in"
                headStyle={{ fontSize: '3em', textAlign: 'center' }}
              >
                <Row>
                  <Col span={11} >
                    <Row>
                      <Form
                        name="normal_login"
                        className="login-form"
                        initialValues={{
                          remember: true,
                        }}
                        onFinish={this.onFinish}
                      >
                        <Form.Item
                          name="username"
                          rules={[
                            {
                              required: true,
                              message: 'Please input your Username!',
                            },
                          ]}
                        >
                          <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" />
                        </Form.Item>
                        <Form.Item
                          name="password"
                          rules={[
                            {
                              required: true,
                              message: 'Please input your Password!',
                            },
                          ]}
                        >
                          <Input.Password
                            prefix={<LockOutlined className="site-form-item-icon" />}
                            type="password"
                            placeholder="Password"
                          />
                        </Form.Item>
                        <Form.Item>
                          <Form.Item name="remember" valuePropName="checked" noStyle>
                            <Checkbox>Remember me</Checkbox>
                          </Form.Item>
                          <Link className="login-form-forgot" to={"/forgot-password"}>Forgot Password?</Link>
                        </Form.Item>

                        <Form.Item>
                          <Button type="primary" htmlType="submit" className="login-form-button"> Log in </Button>
                          {/* <Text type="secondary">Don't have an account? </Text>
                      <Link to={"/register"}>Create Account.</Link> */}
                        </Form.Item>
                      </Form>
                    </Row>
                  </Col>

                  <Col span={2} >
                    <div className="vl">
                      <span className="vl-innertext">or</span>
                    </div>
                  </Col>

                  <Col span={11}>
                    <div className="social-login-container">
                      <Row align="middle">
                        <Loginbygoogle {...this.props} />
                      </Row>
                      <Row align="middle">
                        <Loginbyfacebook />
                      </Row>
                      <Row align="middle">
                        <Loginbylinkedin />
                      </Row>
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col span={24} style={{ textAlign: "center" }}>
                    <Divider orientation="center" className="divider-h ">New To FABNET?</Divider>
                    {/* <Link to={"/register"}>Register</Link> */}
                    <Button block onClick={this.onRegisterClick} style={{ width: "50%" }}>Register</Button>
                  </Col>
                </Row>
              </Card>
            </div>
          </Col>
        </Row>
      </div >
    );
  }
}