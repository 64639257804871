import React, { Component } from "react";
import { database } from "../firebase";
import _ from "lodash";
import "react-quill/dist/quill.snow.css";

import SummaryPosts from "./summaryPosts";
import SearchBox from "../common/searchBox";
import Pagination from "../common/pagination";

import { paginate } from "../common/paginate";

import { Row, Col, Button, Breadcrumb, Divider, Card, Typography } from "antd";
import { Link } from "react-router-dom";
import KnowledgeBase from './knowledgeBase';

const { Title } = Typography;

class KnowledgeBasePosts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      posts: [],
      currentPage: 1,
      pageSize: 3,
      searchQuery: "",
    };
  }

  //Get data from the firebase database (using on command)
  componentDidMount() {
    database.ref('/posts').on("value", (snapshot) => {
      this.setState({
        posts: snapshot.val(),
      });
    });
  }

  handlePageChange = (page) => {
    this.setState({ currentPage: page });
  };

  onMainPageClick = () => {
    const { history } = this.props;
    if (history) history.push("/");
  };

  onKnowledgeBaseClick = () => {
    const { history } = this.props;
    if (history) history.push("/knowledge-base");
  };

  onCreateArticleClick = () => {
    const { history } = this.props;
    if (history) history.push("/knowledge-base-create");
  };

  handleDelete = (postID) => {
    console.log(postID);
    database.ref(`/posts/${postID}`).remove();
  };

  handleSearch = (query) => {
    this.setState({ searchQuery: query, currentPage: 1 });
  };

  // this function is used to filter search sort and paginate data
  getPagedData = () => {
    const { posts: allposts, searchQuery, currentPage, pageSize } = this.state;

    let filteredBeforSearch = Object.values(allposts);

    if (searchQuery)
      filteredBeforSearch = Object.values(allposts).filter(
        (m) =>
          m.title.toLowerCase().includes(searchQuery.toLowerCase()) ||
          m.summary.toLowerCase().includes(searchQuery.toLowerCase())
      );

      console.log(filteredBeforSearch)

    const posts = paginate(filteredBeforSearch, currentPage, pageSize);

    console.log("getData",currentPage)

    return {
      totalCount: filteredBeforSearch.length,
      data: posts,
    };
  };

  render() {
    const { length: count } = this.state.posts;
    const { pageSize, currentPage, searchQuery } = this.state;
      
    const { totalCount, data: posts } = this.getPagedData();
    
    console.log(currentPage)   

    return (
      <React.Fragment>
        <Row>
          <Col span="18" offset="3">
            <Breadcrumb style={{ marginBottom: 10 }}>
              <Breadcrumb.Item>Home</Breadcrumb.Item>
              <Breadcrumb.Item>Knowledge Base</Breadcrumb.Item>
              <Breadcrumb.Item>Search item</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Row>
          <Col span="3" offset="3">
            <br />
            <Title level={3}>¤ Useful Links</Title>
            <Button type="text" onClick={this.onMainPageClick}>
              Main Page      
            </Button>
            <Button type="text" onClick={this.onKnowledgeBaseClick}>
              Knowledge Base
            </Button>
            <br />
            <Button type="text" onClick={this.onCreateArticleClick}>
              Create Article
            </Button>
            <br />
            <Button type="text">Current Events</Button>
            <br />
            <Button type="text">Donate</Button>
            <br />
            <Button type="text">Community portal</Button>
            <br /><br /><br />
            <Title level={3}>¤ Search within</Title>
            <SearchBox value={searchQuery} onChange={this.handleSearch} />
            <Divider style={{ backgroundColor: "#ffffff" }} />
          </Col>
          <Col span="12" offset="0">
          <br />
            <SummaryPosts posts={posts} onDeleteClicked={this.handleDelete}/>
            <br />
            <Pagination
              itemsCount={totalCount}
              pageSize={pageSize}
              currentPage={currentPage}
              onPageChange={this.handlePageChange}
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default KnowledgeBasePosts;
