import axios from '../config/API';
import { trackPromise } from 'react-promise-tracker';
import { environment } from "../environments/environment";

export const ProfileService = {
    getProfile,
    updateProfile,
    updateCompany,
    updateAvatar,
    updateCompanyLogo,
    requestCompany
};

async function getProfile() {
    try {
        const result = await trackPromise(axios.get('/UserManagement/profile/get'));
        return result.data;
    }
    catch (err) {
        console.error(err);
        return { isSuccess: false, message: "Unable to fetch Profile" };
    }
}
async function updateProfile(profile) {
    try {
        let responce = await trackPromise(axios.put('/UserManagement/profile/update', profile))
        return (responce.data);
    }
    catch (err) {
        console.error(err);
        return { isSuccess: false, message: "Unable to update profile" };
    }
}
async function updateAvatar(file) {
    try {
        var bodyFormData = new FormData();
        bodyFormData.append('file', file);
        let responce = await trackPromise(axios.put('/UserManagement/avatar/update', bodyFormData))
        return (responce);
    }
    catch (err) {
        console.error(err);
        return { isSuccess: false, message: "Unable to update profile" };
    }
}
async function updateCompanyLogo(file) {
    try {
        var bodyFormData = new FormData();
        bodyFormData.append('file', file);
        let responce = await trackPromise(axios.put('/UserManagement/company/logo/update', bodyFormData))
        return (responce);
    }
    catch (err) {
        console.error(err);
        return { isSuccess: false, message: "Unable to update profile" };
    }
}
async function updateCompany(companyData) {
    try {
        let responce = await trackPromise(axios.put('/UserManagement/company/update', companyData))
        return (responce.data);
    }
    catch (err) {
        console.error(err);
        return { isSuccess: false, message: "Unable to update company" };
    }
}

async function requestCompany(companyId) {
    try {
        let responce = await trackPromise(axios.put('/UserManagement/company/' + companyId + '/request'))
        return (responce.data);
    }
    catch (err) {
        console.error(err);
        return { isSuccess: false, message: "Unable to send Request" };
    }
}

