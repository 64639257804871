import React from "react";

const InvoiceNotes = ({ value }) => {
    if (value == !null || value != "No_Notes"){
        return (
            <p>
            Notes:
            <br />
            {value.split("\n").map(function (item, key) {
              return (
                <span key={key}>
                  {item}
                  <br />
                </span>
              );
            })}
          </p>
            );
    } else return (<p></p>);

};

export default InvoiceNotes;
