import React from "react";
import "./App.css";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { history } from "./helpers";
import {
  LoginComponent,
  RegisterComponent,
  ForgotPasswordComponent,
  ResetPasswordComponent
} from "./auth";
import { authService } from "./services";

import { Dashboard, LandingPage, UserData, SupplierPublicPage, CompanyMembersPage, EditCompanyPage, CompanyInfoPage,CreateCompanyPage } from "./views";
import SearchResult from "./views/SearchResultPage/SearchResultPage";
import { RFIListPage, ViewRFIPage } from "./views/RFI";
import { RFQListPage, ViewRFQPage } from "./views/RFQ";


import knowledgeBase from "./components/knowledgeBase/knowledgeBase";
import KnowledgeBasePosts from "./components/knowledgeBase/knowledgeBasePosts";
import KnowledgeBaseCreate from "./components/knowledgeBase/knowledgeBaseCreate";
import KnowledgeBaseEdit from "./components/knowledgeBase/knowledgeBaseEdit";
// import Services from "./components/services/services";
// import Services from "./components/products_services/services";
import { Typography, Row, Col } from "antd";
import KnowledgeBasePostDetails from "./components/knowledgeBase/knowledgeBasePostDetails";
import InvoiceCreate from "./components/invoice/inoiceCreate";
import InvoiceDetails from "./components/invoice/invoiceDetails";
import InvoicesSuppliers from "./components/invoice/invoicesSupplier";

import LinkedInPopUp from "./auth/LinkedInPopUp";
import { withToastManager } from "react-toast-notifications";
// import SupplierForm from "./components/suppliers/supplierDetails";
import RouterComponent from "./components/RouterComponent/RouterComponent";


import { FixedSiderLayout, FixedHeaderLayout } from "./layouts"

const { Title } = Typography;

class AppComponent extends React.Component {
  async componentDidMount() {
    if (authService.currentUserValue !== null) {
      await authService.ValidateToken();
    }
  }

  render() {
    return (
      <Router history={history}>
        <Switch>
          <Route exact path={`/register`} render={(props) => <RegisterComponent {...props} toastManager={this.props.toastManager} />} />
          <Route exact path={`/reset-password`} exact component={ResetPasswordComponent} />
          <Route exact path={`/forgot-password`} exact component={ForgotPasswordComponent} />
          <Route exact path={`/reset-password`} exact component={ResetPasswordComponent} />
          <Route exact path={`/login`} render={(props) => <LoginComponent {...props} toastManager={this.props.toastManager} />} />



          <RouterComponent exact path="/search" page={SearchResult} layout={FixedHeaderLayout} />
          <RouterComponent exact path="/suppliers/:id" page={SupplierPublicPage} layout={FixedHeaderLayout} />
          <RouterComponent exact path="/" page={LandingPage} layout={FixedHeaderLayout} />

          <RouterComponent exact path="/dashboard" page={Dashboard} layout={FixedSiderLayout} authorize="isLogged" />

          <RouterComponent exact path="/profile/userData" page={UserData} layout={FixedSiderLayout} authorize="isLogged" />

          <RouterComponent exact path="/manage/company/members" page={CompanyMembersPage} layout={FixedSiderLayout} authorize="isAdmin" />
          <RouterComponent exact path="/manage/company/info" page={CompanyInfoPage} layout={FixedSiderLayout} authorize="isAdmin" />
          <RouterComponent exact path="/manage/company/edit" page={EditCompanyPage} layout={FixedSiderLayout} authorize="isAdmin" />
          <RouterComponent exact path="/manage/company/create" page={CreateCompanyPage} layout={FixedSiderLayout} authorize="isLogged" />

          <RouterComponent exact path="/manage/rfi/list" page={RFIListPage} layout={FixedSiderLayout} authorize="isLogged" />
          <RouterComponent exact path="/manage/rfi/:id" page={ViewRFIPage} layout={FixedSiderLayout} authorize="isLogged" />
          
          <RouterComponent exact path="/manage/rfq/list" page={RFQListPage} layout={FixedSiderLayout} authorize="isLogged" />
          <RouterComponent exact path="/manage/rfq/:id" page={ViewRFQPage} layout={FixedSiderLayout} authorize="isLogged" />



          {/* -------------------------------------------------------------------------------------------------------------------------------------- */}
          <Route exact path={`/invoice`} render={(props) => <InvoicesSuppliers {...props} toastManager={this.props.toastManager} />} />
          <Route exact path={`/invoice-create`} render={(props) => <InvoiceCreate {...props} toastManager={this.props.toastManager} />} />
          <Route path="/invoice/:id" render={(props) => <InvoiceDetails {...props} toastManager={this.props.toastManager} />} />

          <Route exact path={`/knowledge-base`} render={(props) => <knowledgeBase {...props} toastManager={this.props.toastManager} />} />
          <Route exact path={`/knowledge-base-posts`} render={(props) => <KnowledgeBasePosts {...props} toastManager={this.props.toastManager} />} />
          <Route exact path={`/knowledge-base-create`} render={(props) => <KnowledgeBaseCreate {...props} toastManager={this.props.toastManager} />} />
          <Route path="/knowledge-base-edit/:id" render={(props) => <KnowledgeBaseEdit {...props} toastManager={this.props.toastManager} />} />
          <Route path={`/posts/:id`} render={(props) => <KnowledgeBasePostDetails {...props} toastManager={this.props.toastManager} />} />



          {/* <Route exact path="/dashboard" component={PrivateRoutes} /> */}
          {/* 
            <Route exact path="/manage/suppliers" component={PrivateRoutes} />
            <Route exact path="/manage/suppliers/:id" component={PrivateRoutes} />
            <Route exact path="/manage/suppliers/create" component={PrivateRoutes} />
            <Route exact path="/manage/suppliers/edit/:id" component={PrivateRoutes} />
            <Route exact path="/manage/customers" component={PrivateRoutes} />

            <Route exact path="/manage/company/members" component={PrivateRoutes} />

            <Route exact path="/profile/userData" component={PrivateRoutes} />

            <Route exact path="/profile/view" component={PrivateRoutes} />
            <Route exact path="/profile/edit" component={PrivateRoutes} />
            <Route exact path="/manage/customers" component={PrivateRoutes} />
            <Route exact path="/manage/companies" component={PrivateRoutes} />
            <Route exact path="/profile/company" component={PrivateRoutes} />
            <Route exact path="/profile/company/create" component={PrivateRoutes} />

            <Route exact path="/company/RFIList" component={PrivateRoutes} />
            <Route exact path="/company/RFQList" component={PrivateRoutes} /> */}

          <Route exact path="/linkedin" component={LinkedInPopUp} />
        </Switch>

      </Router>
    );
  }
}

const App = withToastManager(AppComponent);
export default App;

const MetalIndustryComponent = (props) => {
  return (
    <React.Fragment>
      <Row align="middle" justify="center" style={{ marginTop: 100 }}>
        <Col>
          <Title>Metal Industry</Title>
        </Col>
      </Row>
    </React.Fragment>
  );
};

const RubberIndustryComponent = (props) => {
  return (
    <React.Fragment>
      <Row align="middle" justify="center" style={{ marginTop: 100 }}>
        <Col>
          <Title>Rubber Industry</Title>
        </Col>
      </Row>
    </React.Fragment>
  );
};

const PlasticIndustryComponent = (props) => {
  return (
    <React.Fragment>
      <Row align="middle" justify="center" style={{ marginTop: 100 }}>
        <Col>
          <Title>Plastic Industry</Title>
        </Col>
      </Row>
    </React.Fragment>
  );
};

const SeminarsComponent = (props) => {
  return (
    <React.Fragment>
      <Row align="middle" justify="center" style={{ marginTop: 100 }}>
        <Col>
          <Title>Seminars</Title>
        </Col>
      </Row>
    </React.Fragment>
  );
};

const WorkshopsComponent = (props) => {
  return (
    <React.Fragment>
      <Row align="middle" justify="center" style={{ marginTop: 100 }}>
        <Col>
          <Title>Workshops</Title>
        </Col>
      </Row>
    </React.Fragment>
  );
};
