import React from "react";
import { Tabs, Form, Row, Col, Input, Button, PageHeader, Select, Upload, Divider } from "antd";
import 'antd/dist/antd.css';
import { companyService, userService } from "../../services";
import { withToastManager } from "react-toast-notifications";
import { UploadOutlined, InboxOutlined } from '@ant-design/icons';
import LogoUpload from "../../components/ImageUploader/LogoUpload";
import ProductServiceCreation from "../../components/Tables/ProductServiceCreation";
const { TabPane } = Tabs;

const { Option } = Select;


const normFile = (e) => {
    console.log('Upload event:', e);
    if (Array.isArray(e)) {
        return e;
    }
    return e && e.fileList;
};


class CreateCompany extends React.Component {
    form = null;

    constructor(props) {
        super(props);
        this.state = {
            addressEntered: false,
            companyId: 0,
            company: {
                name: "",
                phone: "",
                email: "",
                fax: "",
                keyPersonnel: "",
                aboutUs: "",
                businessRegistration: "",
                vATOrTIN: "",
                website: "",
                facebookHandle: "",
                linkedInHandle: "",
                twitterHandle: ""
            },
            address: {
                houseNumber: "",
                street: "",
                city: "",
                state_District: "",
                zipCode: "",
                country: "",
                latitude: "",
                longitude: ""
            },
            fields: [],
            services: [],
            products: [],
            certifications: [],
        };
    }

    getFields = () => {
        const children = [];

        for (let i = 0; i < fields.length; i++) {
            var field = fields[i];
            children.push(
                <Col span={8} key={i}>
                    <Form.Item
                        name={field.name}
                        label={field.label}
                        rules={[
                            {
                                required: field.required,
                                message: field.label + " is Required",
                            },
                        ]}
                    >
                        <Input placeholder={field.label} />
                    </Form.Item>
                </Col>
            );
        }

        return children;
    };

    getAddressFields = () => {
        const children = [];

        for (let i = 0; i < addressFields.length; i++) {
            var field = addressFields[i];
            children.push(
                <Col span={8} key={i}>
                    <Form.Item
                        name={field.name}
                        label={field.label}
                        rules={[
                            {
                                required: field.required,
                                message: field.label + " is Required",
                            },
                        ]}
                    >
                        <Input name={field.name} placeholder={field.label} onChange={this.onAddressFieldChange} />
                    </Form.Item>
                </Col>
            );
        }
        return children;
    };

    onSubmit = async () => {
        let company = {};
        fields.forEach(field => {
            company[field.name] = this.form.getFieldValue(field.name);
        });

        let address = {};

        addressFields.forEach(field => {
            address[field.name] = this.form.getFieldValue(field.name);
        });

        company.address = address;
        let result = await companyService.createCompany(company);

        if (!result.isSuccess) {
            this.props.toastManager.add(result.message, { appearance: 'error', autoDismiss: true });
        }
        else {
            const toast = "Company Create request sent.";
            this.props.toastManager.add(toast, { appearance: 'success', autoDismiss: true });
            companyService.requestCompanyJoin(null, result.dto).then(res => {
                debugger
                if (res.isSuccess) {
                    this.props.toastManager.add("Company Request Created.", { appearance: 'success', autoDismiss: true });
                }
                else {
                    console.error(res.message)
                    this.props.toastManager.add(res.message, { appearance: 'error', autoDismiss: true });
                }
            });

            this.props.history.push('/profile/userData');
        }
    };

    onAddressChange = (result) => {
        if (!result || result.length === 0) return;
        let newAddress = {
            streetAddress: null,
            city: null,
            state: null,
            country: null,
            postalCode: null,
            latitude: null,
            longitude: null,
            suite_Terminal_Gate: null,
        };

        addressFields.forEach((field) => (newAddress[field.name] = null));

        const address_components = result[0].gmaps.address_components;

        address_components.forEach((component) => {
            const addressType = component.types[0];
            const val = component.short_name;

            switch (addressType) {
                case "street_number":
                    newAddress.streetAddress = val;
                    break;
                case "route":
                    newAddress.streetAddress += " " + val;
                    break;
                case "locality":
                    newAddress.city = val;
                    break;
                case "administrative_area_level_1":
                    newAddress.state = val;
                    break;
                case "country":
                    newAddress.country = val;
                    break;
                case "postal_code":
                    newAddress.postalCode = val;
                    break;
                default:
                    break;
            }
        });

        newAddress.latitude = result[0].lat;
        newAddress.longitude = result[0].lng;

        this.form.setFieldsValue({ "streetAddress": newAddress["streetAddress"] });
        this.form.setFieldsValue({ "city": newAddress["city"] });
        this.form.setFieldsValue({ "state": newAddress["state"] });
        this.form.setFieldsValue({ "country": newAddress["country"] });
        this.form.setFieldsValue({ "postalCode": newAddress["postalCode"] });
        this.form.setFieldsValue({ "latitude": newAddress["latitude"] });
        this.form.setFieldsValue({ "longitude": newAddress["latitude"] });

        this.setState({ addressEntered: true });
    };

    onAddressFieldChange = (e) => {
        const name = e.target.name;
        const value = e.target.value
        let pair = {};
        pair[name] = value;
        this.form.setFieldsValue(pair);
    }


    render() {

        return (
            <React.Fragment>
                <PageHeader
                    style={{ marginTop: "0px", paddingTop: "0px" }}
                    title={"Create Company"}
                />


                {/* <Tabs defaultActiveKey="1" size="small" style={{ marginBottom: 32 }}>
                    <TabPane tab="Basic Information" key="1"> */}
                <LogoUpload
                // logo={this.state.company.logo}
                // companyId={this.state.companyId}
                />
                <Form
                    style={{ marginTop: "25px" }}
                    ref={(ref) => (this.form = ref)}
                    name="createcompany"
                    labelCol={{ span: 8 }}
                    onFinish={this.onSubmit}
                // fields={this.state.fields}
                >
                    <Row gutter={24}>
                        {this.getFields()}
                        <Col span={8}>
                            <Form.Item name="industry" label="Industry" rules={[{ required: true, message: "Select an Industry" }]}>
                                <Select
                                    placeholder="Select an Industry"
                                    allowClear
                                >
                                    <Option value={6}>ElectricalAndElectronics</Option>
                                    <Option value={1}>FoodAndBeverage</Option>
                                    <Option value={7}>Mineral</Option>
                                    <Option value={0}>Other</Option>
                                    <Option value={4}>PetroleumAndPlastics</Option>
                                    <Option value={5}>Steel</Option>
                                    <Option value={2}>Textile</Option>
                                    <Option value={3}>Wood</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Divider orientation="left">Addresss</Divider>
                    <Row gutter={24}>{this.getAddressFields()}</Row>
                    <Row>
                        <Col
                            span={24}
                            style={{
                                textAlign: "right",
                            }}
                        >
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>
                            <Button
                                style={{
                                    margin: "0 8px",
                                }}
                                onClick={() => {
                                    this.form.resetFields();
                                }}
                            >
                                Reset
                            </Button>
                            <Button
                                danger
                                style={{ margin: "0 8px", }}
                                onClick={() => this.props.history.goBack()}
                            >
                                Cancel
                            </Button>
                        </Col>
                    </Row>
                </Form>
                {/* </TabPane>
                    <TabPane tab="Products & Services" key="2">

                        <ProductServiceCreation
                            companyId={this.state.companyId}
                            type="Products"
                            data={this.state.products}
                        />
                        <ProductServiceCreation
                            companyId={this.state.companyId}
                            type="Services"
                            data={this.state.services}
                        />


                    </TabPane>
                </Tabs> */}
            </React.Fragment >
        );
    }
}


export const CreateCompanyPage = withToastManager(CreateCompany);

const fields = [
    { name: "name", label: "Company Name", required: true },
    { name: "phone", label: "Phone Number", required: true },
    { name: "email", label: "Email Address", required: true },
    { name: "fax", label: "Fax", required: false },
    { name: "keyPersonnel", label: "Key Personnel", required: true },
    { name: "aboutUs", label: "Description", required: true },
    { name: "businessRegistration", label: "Business Reg #", required: true },
    { name: "vatOrTIN", label: "VAT Or TIN", required: true },
    { name: "website", label: "Website", required: false },
    { name: "facebookHandle", label: "Facebook Url", required: false },
    { name: "linkedInHandle", label: "LinkedIn Url", required: false },
    { name: "twitterHandle", label: "Twitter Link", required: false }
];

const addressFields = [
    {
        name: "houseNumber",
        label: "Building Number",
        required: false,
        hidden: false,
    },
    {
        name: "street",
        label: "Street Address",
        required: true,
        hidden: false,
    },
    { name: "city", label: "City", required: true, hidden: false },
    { name: "state_District", label: "Provice/District", required: true, hidden: false },
    { name: "zipCode", label: "Zip Code", required: false, hidden: false },
    { name: "country", label: "Country", required: true, hidden: false },
    { name: "latitude", label: "Latitude", required: false, hidden: true },
    { name: "longitude", label: "Longitude", required: false, hidden: true },
];

