import React, { Component } from "react";
import { database } from "../firebase";
import _ from "lodash";
import ReactQuill from "react-quill";
import { useHistory } from "react-router-dom";
import "react-quill/dist/quill.snow.css";
import "antd/dist/antd.css";
import { Typography, Input, Row, Col, Select, Option } from "antd";

class KnowledgeBaseCreate extends Component {
  constructor(props) {
    super(props);

    // calculate the current date
    var today = new Date(),
            date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();

    this.state = {
      title: "",
      category: "",
      body: "",
      createdDate: date,
      lastModifiedDate: date,
      posts: "",
    };

    //bind
    this.onHandleChangeBody = this.onHandleChangeBody.bind(this);
    this.onHandleSubmit = this.onHandleSubmit.bind(this);
    this.onHandleChangeCategory = this.onHandleChangeCategory.bind(this);
  }

  onHandleChangeBody(e) {
    this.setState({
      body: e,
    });
  }

  onHandleChangeCategory(e) {
    this.setState({
      category: e,
    });
  }

  onHandleSubmit(e) {
    e.preventDefault();
    const post = {
      _id:"FKB".concat(Date.now()),
      title: this.state.title,
      body: this.state.body,
      category: this.state.category,
      summary: this.state.summary,
      createdDate: this.state.createdDate,
      lastModifiedDate: this.state.lastModifiedDate
    };
    database.ref(`/posts/${post._id}`).set(post);
    this.setState({
      _id: "",
      title: "",
      body: "",
      category: "",
      summary: "",
    });
    this.redirectToHome();
  }

  redirectToHome = () => {
    const { history } = this.props;
    if (history) history.push("/knowledge-base-posts");
  };

  render() {
    const { Option } = Select;
    const { Title } = Typography;
    const { TextArea } = Input;

    return (
      <div className="container">
        <Row>
          <Col span="3" offset="3"></Col>
          <Col span="12" offset="0">
        <br />
        <Title>Create Article</Title>
        <form onSubmit={this.onHandleSubmit}>
          <Row>
            <Col span={6}>
              <Select
                defaultValue="All Categories"
                style={{ width: "100%" }}
                onChange={this.onHandleChangeCategory}
              >
                <Option value="All Categories">All Categories</Option>
                <Option value="Aerospace Engineering">
                  Aerospace Engineering
                </Option>
                <Option value="Automotive Engineering">
                  Automotive Engineering
                </Option>
                <Option value="Electrical Engineering">
                  Electrical Engineering
                </Option>
                <Option value="Civil Engineering">Civil Engineering</Option>
                <Option value="Production Engineering">
                  Production Engineering
                </Option>
                <Option value="CAD Models">CAD Models</Option>
                <Option value="3D Printing">3D Printing</Option>
              </Select>
            </Col>
            <Col span={18}>
              <Input
                value={this.state.title}
                type="text"
                name="title"
                placeholder="Title"
                onChange={(e) => {
                  this.setState({ title: e.target.value });
                }}
              />
            </Col>
          </Row>
          <br />
          <TextArea
            rows={2}
            name="summary"
            placeholder="Please write a summary for your article here"
            onChange={(e) => {
              this.setState({ summary: e.target.value });
            }}
          />
          <br />
          <br />
          <div className="form-group">
            <ReactQuill
              modules={KnowledgeBaseCreate.modules}
              formats={KnowledgeBaseCreate.formats}
              value={this.state.body}
              placeholder="Body"
              onChange={this.onHandleChangeBody}
            />
          </div>
          <br />
          <button>Post</button>
        </form>
        </Col>
        </Row>
      </div>
    );
  }
}

KnowledgeBaseCreate.modules = {
  toolbar: [
    [{ font: [] }, { size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{ list: "ordered" }, { list: "bullet" }],
    ["link", "image", "video"],
    ["code-block"],
  ],
};

KnowledgeBaseCreate.formats = [
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "link",
  "image",
  "video",
  "code-block",
];

export default KnowledgeBaseCreate;
