import React, { Component } from "react";
import { Card, Typography, Button } from "antd";
import { Link } from "react-router-dom";
import { database } from "../firebase";
import _ from "lodash";
import "react-quill/dist/quill.snow.css";

import { SaveOutlined } from "@ant-design/icons";

const { Text } = Typography;

class SummaryInvoices extends Component {
  
  render() {
    const { invoices, onDeleteClicked } = this.props;
   

    return _.map(invoices, (invoice) => {
      return (
        <Card
          key={invoice._id}
          title={<Link to={`/invoice/${invoice._id}`}>Invoice Id : {invoice.invoiceId}</Link>}
          extra={
            <React.Fragment>
            <Link onClick={() => onDeleteClicked(invoice._id)}>Delete  </Link>
            </React.Fragment>
          }
          style={{ marginBottom: 15 }}
        >
          <p>
            Customer Name : {invoice.customerName} <br/>
            Billing Address   : {invoice.billingAddress}<br/>
            Invoice Date       : {invoice.invoiceDate}
          </p>
        </Card>
      );
    });

  }
}
export default SummaryInvoices;