import React, { Component } from "react";
import { Card, Typography, Button } from "antd";
import { Link } from "react-router-dom";
import { database } from "../firebase";
import _ from "lodash";
import "react-quill/dist/quill.snow.css";

import { SaveOutlined } from "@ant-design/icons";

const { Text } = Typography;

class SummaryPosts extends Component {
  
  render() {
    const { posts, onDeleteClicked } = this.props;
   

    return _.map(posts, (post) => {
      return (
        <Card
          key={post._id}
          title={<Link to={`/posts/${post._id}`}>{post.title}</Link>}
          extra={
            <React.Fragment>
            <Link onClick={() => onDeleteClicked(post._id)}>Delete  </Link>
            <Link to={`/knowledge-base-edit/${post._id}`}>  edit</Link>
            </React.Fragment>
          }
          style={{ marginBottom: 15 }}
        >
          <p>{post.summary}</p>
        </Card>
      );
    });

  }
}
export default SummaryPosts;