import React, { Component } from "react";
import Popup from "reactjs-popup";
// import { getSupplier } from "../../services/fakeServices/fakeSupplierService";
import { fetchSupplier } from "../../services/supplierService";
import { rfiService, rfqService, userService } from "../../services";
import {
  Row,
  Col,
  Card,
  Typography,
  Breadcrumb,
  Tooltip,
  Button,
  Tabs,
  Form,
  Input,
  DatePicker,
  Anchor,
  message,
  Upload,
  List
} from "antd";
import { FacebookFilled, TwitterSquareFilled, LinkedinFilled, UploadOutlined } from '@ant-design/icons';
import SupplierDetailsMap from "../../components/suppliers/SupplierDetailsMap";
import { withToastManager } from "react-toast-notifications";

import "./supplierDetails.css";

const { Link } = Anchor;
const { Text, Paragraph, Title } = Typography;
const { TabPane } = Tabs;
const { TextArea } = Input;

class SupplierPage extends Component {
  state = {
    supplier: {},

    logo: "",
    supllierName: "",
    supplierDiscription: "",

    supplierAddress: "",
    lat: 0,
    lng: 0,

    IndustryType: "",
    businessRegistration: "",
    vatNo: "",

    certificateList: [],
    products: [],
    services: [],

    keyPerson: "",
    phone: "",
    email: "",
    website: "",
    facebook: "",
    linkedin: "",
    twitter: "",

    // supplierProductTypeName: "",
    // supplierCompanyTypeName: "",
    // productList: [],
    // certificateList: [],

    activeTabKey: "1",

    RFIAttchments: [],
    RFQAttchments: [],
  };

  componentDidMount() {

    const supplierId = this.props.match.params.id;
    console.log(this.props.match.params);

    const query = new URLSearchParams(this.props.location.search);
    let activeTabKey = query.get('tab') || '1'
    console.log(activeTabKey);

    if (supplierId === "new") return;

    fetchSupplier(supplierId)
      .then(result => {
        let supplier = result.dto
        console.log(supplier);
        if (!supplier) return this.props.history.replace("/not-found");
        this.setState({
          logo: supplier.logo,
          supplierName: supplier.name,
          supplierDiscription: supplier.aboutUs,
          supplierAddress: supplier.address,

          IndustryType: supplier.industry,
          businessRegistration: supplier.businessRegistration,
          vatNo: supplier.vatOrTIN,

          certificateList: supplier.certifications,
          productList: supplier.products,
          services: supplier.services,

          keyPerson: supplier.keyPerson,
          phone: supplier.phone,
          email: supplier.email,
          website: supplier.website,
          facebook: supplier.facebookHandle,
          linkedin: supplier.linkedInHandle,
          twitter: supplier.twitterHandle,

          lat: supplier.address.lat,
          lng: supplier.address.lng,

          activeTabKey: activeTabKey,
        });
      });

  }

  onButtonPress = () => {
    this.setState({
      activeTabKey: "5",
    });
  };

  onTabChange = (activeTabKey) => {
    this.setState({ activeTabKey });

  };


  render() {
    // const productLists = this.state.products;
    // const productTitles = productLists.map((product) => (
    //   <li>{product.title}</li>
    // ));
    const productCards = this.state.products.map((product, index) => (
      <Col span={8}>
        <Card title={`${index + 1}. ${product.title}`} bordered={true} style={{ minHeight: 200 }}>
          <Paragraph ellipsis={{ rows: 3, expandable: true, symbol: 'more' }}>{product.description}</Paragraph>
        </Card>
      </Col>
    ));
    const serviceCards = this.state.services.map((service, index) => (
      <Col span={8} key={index}>
        <Card title={`${index + 1}. ${service.title}`} bordered={true} style={{ minHeight: 200 }}>
          <Paragraph ellipsis={{ rows: 3, expandable: true, symbol: 'more' }}>{service.description}</Paragraph>
        </Card>
      </Col>
    ));

    const certificateLists = this.state.certificateList;
    const certificateListItems = certificateLists.map((certificateLists, index) => (
      <li key={index}>{certificateLists}</li>
    ));

    const onFinish_RFQ = (values) => {
      console.log("Success RFQ:", values);
      this.setState({ uploading: true });

      rfqService.SendRFQ({
        title: values.RFQTitle,
        responceNeededBy: values.needResponseBy._d,
        message: values.RFQMessage,
        supplierId: this.props.match.params.id,
        files: this.state.RFQAttchments
      })
        .then(res => {
          console.log(res);
          if (res.isSuccess) {
            this.props.toastManager.add(res.message, { appearance: 'success', autoDismiss: true });
          } else {
            this.props.toastManager.add(res.message, { appearance: 'error', autoDismiss: true });
          }
          this.setState({ uploading: false });
        })
    };
    const onFinishFailed_RFQ = (errorInfo) => {
      console.log("Failed RFQ:", errorInfo);
      this.props.toastManager.add("Failed RFQ:" + errorInfo, { appearance: 'error', autoDismiss: true });

    };

    const onFinish_RFI = (values) => {
      console.log("Success RFI:", values, this.state.RFIAttchments);
      this.setState({ uploading: true });
      rfiService.SendRFI({
        title: values.RFITitle,
        responceNeededBy: values.needResponseBy._d,
        message: values.RFIMessage,
        supplierId: this.props.match.params.id,
        files: this.state.RFIAttchments
      })
        .then(res => {
          console.log(res);
          if (res.isSuccess) {
            this.props.toastManager.add(res.message, { appearance: 'success', autoDismiss: true });
          } else {
            this.props.toastManager.add(res.message, { appearance: 'error', autoDismiss: true });
          }
          this.setState({ uploading: false });
        })

    };
    const onFinishFailed_RFI = (errorInfo) => {
      console.log("Failed RFI:", errorInfo);
      this.props.toastManager.add("Failed RFI:" + errorInfo, { appearance: 'error', autoDismiss: true });
    };

    const { uploading, RFIAttchments, RFQAttchments } = this.state;
    const RFQFileProps = {
      onRemove: file => {
        this.setState(state => {
          const index = state.RFQAttchments.indexOf(file);
          const newFileList = state.RFQAttchments.slice();
          newFileList.splice(index, 1);
          return {
            RFQAttchments: newFileList,
          };
        });
      },
      beforeUpload: file => {
        this.setState(state => ({
          RFQAttchments: [...state.RFQAttchments, file],
        }));
        return false;
      },
      RFQAttchments,
    };

    const RFIFileProps = {
      onRemove: file => {
        this.setState(state => {
          const index = state.RFIAttchments.indexOf(file);
          const newFileList = state.RFIAttchments.slice();
          newFileList.splice(index, 1);
          return {
            RFIAttchments: newFileList,
          };
        });
      },
      beforeUpload: file => {
        this.setState(state => ({
          RFIAttchments: [...state.RFIAttchments, file],
        }));
        return false;
      },
      RFIAttchments,
    };


    return (
      <React.Fragment>
        <Row>
          <Col span="18" offset="3">
            <Breadcrumb style={{ marginBottom: 10 }}>
              <Breadcrumb.Item>Home</Breadcrumb.Item>
              <Breadcrumb.Item>Search Field</Breadcrumb.Item>
              <Breadcrumb.Item>Company Details</Breadcrumb.Item>
              <Breadcrumb.Item>{this.state.supplierName}</Breadcrumb.Item>
            </Breadcrumb>
          </Col>
        </Row>
        <Row>
          <Col span="18" offset="3">
            <Card style={{ marginBottom: 20 }}>
              <Row>
                <Col span="3">
                  <img
                    src={this.state.logo}
                    alt="Supplier Logo"
                    width="60%"
                  ></img>
                </Col>
                <Col span="12" offset="0">
                  <Title level={3}>{this.state.supplierName}</Title>
                  <p>
                    {this.state.supplier.verified ? (
                      <Tooltip title="The supplier location, services and product list is verified">
                        <Text code>√ Fablanka Verified | </Text>
                      </Tooltip>
                    ) : (
                      ""
                    )}{" "}
                    {this.state.IndustryType}
                  </p>
                  <p>
                    {this.state.supplierAddress.fullAddress}  |
                    <Popup modal trigger={<a>Map</a>}>
                      {(close) => (
                        <SupplierDetailsMap
                          close={close}
                          lat={this.state.lat}
                          lng={this.state.lng}
                        />
                      )}
                    </Popup>
                  </p>

                </Col>
                <Col span="9" offset="0" align="center">
                  <Button
                    type="primary"
                    block
                    style={{ marginBottom: 20 }}
                    onClick={this.onButtonPress}
                  >
                    Request a Quotation
                  </Button>
                  <p>
                    <Tooltip title={this.state.phone}>
                      <a href={`tel: ${this.state.phone}`}>
                        Call the Suppier
                      </a>
                    </Tooltip>
                    |
                    <Tooltip title={this.state.email}>
                      <a href={`mailto: ${this.state.email}`}>
                        {" "}Email the Supplier
                      </a>
                    </Tooltip>
                  </p>
                  {this.state.facebook ?
                    (<Tooltip title={this.state.facebook}>
                      <Button icon={<FacebookFilled />} href={this.state.facebook} />
                    </Tooltip>
                    ) : ("")
                  }
                  {this.state.twitter ?
                    (<Tooltip title={this.state.twitter}>
                      <Button icon={<TwitterSquareFilled />} href={this.state.twitter} />
                    </Tooltip>
                    ) : ("")
                  }
                  {this.state.linkedin ?
                    (<Tooltip title={this.state.linkedin}>
                      <Button icon={<LinkedinFilled />} href={this.state.linkedin} />
                    </Tooltip>
                    ) : ("")
                  }
                  {this.state.website ?
                    (<Tooltip title={this.state.website}>
                      <Button href={this.state.website} ><strong>WWW.</strong></Button>
                    </Tooltip>
                    ) : ("")
                  }
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>

        <Row>
          <Col span="18" offset="3" style={{ marginBottom: 20 }}>
            <div className="card-container">


              <Tabs
                type="card"
                activeKey={this.state.activeTabKey}
                onChange={this.onTabChange}
              >
                <TabPane tab="Profile" key="1" >
                  <Row>
                    <Col span="24" offset="0">
                      <Card>
                        <Title level={3}>Business Description</Title>
                        <Paragraph ellipsis={{ rows: 3, expandable: true, symbol: 'more' }}>{this.state.supplierDiscription}</Paragraph>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col span="12" offset="0">
                      <Card>
                        <Title level={4}>Certification List</Title>
                        {certificateLists.length === 0 ? (
                          "No Certificates Available"
                        ) : (
                          <ul>{certificateListItems}</ul>
                        )}
                      </Card>
                    </Col>
                    <Col span="12" offset="0">
                      <Card>
                        <Title level={4}>Business Details</Title>
                        <p>
                          <b>Primary Company Type:  </b>
                          {this.state.IndustryType}{" "}
                        </p>
                        <p>
                          <b>Business Registration Number:  </b>
                          {this.state.businessRegistration}{" "}
                        </p>
                        <p>
                          <b>Vat Number:  </b>
                          {this.state.vatNo}{" "}
                        </p>
                        <p>
                          <b>Additional Services:  </b>
                          {this.state.supplier.additionalActivities}{" "}{/* Not In the DTO */}
                        </p>
                        <p>
                          <b>Established Year:  </b>
                          {this.state.supplier.estYear}{" "}     {/* Not In the DTO */}
                        </p>
                      </Card>
                    </Col>
                  </Row>
                </TabPane>

                <TabPane tab="Additional Information " key="2">
                  <Row>
                    <Col span="24" offset="0">
                      <Card>
                        <Title level={4}>Additional Information</Title>
                        <p>Content of Aditional Information</p>
                        <p>Content of Aditional Information</p>
                        <p>Content of Aditional Information</p>
                      </Card>
                    </Col>
                  </Row>
                </TabPane>

                <TabPane tab="Services" key="3">
                  <Row>
                    <Col span="24" offset="0">
                      <Card title="Service Information" >
                        <Row gutter={[16, 16]}>
                          {serviceCards}
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                </TabPane>

                <TabPane tab="Products" key="4">
                  <Row>
                    <Col span="24" offset="0">
                      <Card title="Product Information">
                        <Row gutter={[16, 16]}>
                          {productCards}
                        </Row>
                      </Card>
                    </Col>
                  </Row>
                </TabPane>

                <TabPane tab="Request For Quotation " key="5">
                  <Row>
                    <Col span="24" offset="0">
                      <Card>
                        <Title level={4}>
                          Request Quotation from {this.state.supplierName}
                        </Title>
                        <Form
                          onFinish={onFinish_RFQ}
                          onFinishFailed={onFinishFailed_RFQ}
                        >
                          <Form.Item
                            label="Title of the Quotation"
                            name="RFQTitle"
                            rules={[
                              {
                                required: true,
                                message: "Please input your username!",
                              },
                            ]}
                          >
                            <Input
                              style={{ width: 750 }}
                              placeholder="Please input tilte of your message"
                            />
                          </Form.Item>
                          <Form.Item
                            label="Response Needed By"
                            name="needResponseBy"
                            rules={[
                              {
                                type: "object",
                                required: true,
                                message: "Please select time!",
                              },
                            ]}
                          >
                            <DatePicker />
                          </Form.Item>
                          <Form.Item
                            label="Message to Supplier"
                            name="RFQMessage"
                            rules={[
                              {
                                required: true,
                                message: "Please input your message!",
                              },
                            ]}
                          >
                            <TextArea
                              rows={4}
                              style={{ width: 750 }}
                              placeholder="Please input your message"
                            />
                          </Form.Item>

                          <Form.Item>
                            <Upload {...RFQFileProps}>
                              <Button icon={<UploadOutlined />}>Select File</Button>
                            </Upload>
                          </Form.Item>

                          <Form.Item>
                            <Button type="primary" htmlType="submit">
                              Send
                            </Button>
                          </Form.Item>
                        </Form>
                      </Card>
                    </Col>
                  </Row>
                </TabPane>

                <TabPane tab="Request For Information" key="6">
                  <Row>
                    <Col span="24" offset="0">
                      <Card>
                        <Title level={4}>
                          Request Information from {this.state.supplierName}
                        </Title>
                        <Form
                          onFinish={onFinish_RFI}
                          onFinishFailed={onFinishFailed_RFI}
                        >
                          <Form.Item
                            label="Title"
                            name="RFITitle"
                            rules={[
                              {
                                required: true,
                                message: "Please input your username!",
                              },
                            ]}
                          >
                            <Input
                              style={{ width: 750 }}
                              placeholder="Please input tilte of your message"
                            />
                          </Form.Item>
                          <Form.Item
                            label="Response Needed By"
                            name="needResponseBy"
                            rules={[
                              {
                                type: "object",
                                required: true,
                                message: "Please select time!",
                              },
                            ]}
                          >
                            <DatePicker />
                          </Form.Item>
                          <Form.Item
                            label="Message to Supplier"
                            name="RFIMessage"
                            rules={[
                              {
                                required: true,
                                message: "Please input your message!",
                              },
                            ]}
                          >
                            <TextArea
                              rows={4}
                              style={{ width: 750 }}
                              placeholder="Please input your message"
                            />
                          </Form.Item>

                          <Upload {...RFIFileProps}>
                            <Button icon={<UploadOutlined />}>Select Files</Button>
                          </Upload>


                          <List
                            itemLayout="horizontal"
                            dataSource={this.state.RFIAttchments}
                            renderItem={item => (
                              <List.Item>
                                <List.Item.Meta
                                  title={item.name}
                                  description={item.path}
                                />
                              </List.Item>
                            )}
                          />

                          {/* <List>
                            {console.log(this.state.RFIAttchments)}
                            {this.state.RFIAttchments.forEach((attachment, index) => {
                              console.log(attachment);
                              <List.Item key={index}>
                                <List.Item.Meta
                                  title={attachment.name}
                                  description={attachment.path}
                                />
                              </List.Item>
                            })}
                          </List> */}

                          <Form.Item>

                            <Button type="primary" htmlType="submit" >
                              Send
                            </Button>

                          </Form.Item>
                        </Form>
                      </Card>
                    </Col>
                  </Row>
                </TabPane>

                <TabPane tab="Contact Supplier " key="7">
                  <Row>
                    <Col span="24" offset="0">
                      <Card>
                        <Title level={4}>Contact Supplier</Title>
                        <p>
                          <b>Telephone Number:  </b>
                          <a href={`tel: ${this.state.phone}`}>
                            {this.state.phone}
                          </a>
                        </p>
                        <p>
                          <b>E-mail Address:  </b>
                          <a href={`mailto: ${this.state.email}`}>
                            {this.state.email}{" "}
                          </a>
                        </p>
                        <p>
                          <b>Fax Number:  </b>
                          <a href="#">{this.state.supplierFax}</a>
                        </p>
                      </Card>
                    </Col>
                  </Row>
                </TabPane>

              </Tabs>
            </div>
          </Col>
        </Row>
      </React.Fragment >
    );
  }
}

export const SupplierPublicPage = withToastManager(SupplierPage);