import React from "react";
import { Typography, Space } from "antd";

import {
  SortAscendingOutlined,
  SortDescendingOutlined,
} from "@ant-design/icons";

const { Text } = Typography;

const SortListGroup = ({
  items,
  textProperty,
  valueProperty,
  selectedPath,
  selectedOrder,
  onItemSelect,
}) => {
  return (
    <Space direction="vertical">
      {items.map((item, index) => (

        <Text
          key={item[valueProperty]}
          type={item.path === selectedPath ? "danger" : "secondary"}
          onClick={() => onItemSelect(item)}
          style={{ cursor: "pointer" }}
        >

          {" "}

          {
            selectedOrder
              ? (item.path === selectedPath
                ? (selectedOrder === "asc"
                  ? (<SortAscendingOutlined />)
                  : (<SortDescendingOutlined />)
                )
                : ("")
              )
              : ("")
          }
          {item[textProperty]}
        </Text>
      ))}
    </Space>
  );
};

SortListGroup.defaultProps = {
  textProperty: "name",
  valueProperty: "id",
  selectedOrder: "",
};

export { SortListGroup };
