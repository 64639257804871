export const sortList = [
  { id: 0, path: "service.title", name: "Service Title" },
  { id: 1, path: "product.title", name: "Product Title" },
  { id: 2, path: "company.name", name: "Company Name" },
  { id: 3, path: "company.type", name: "Company Type" },
  { id: 4, path: "district.name", name: "District" }
];

export function getSortList() {
  return sortList.filter(p => p);
}
